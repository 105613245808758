/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  asMarkdownTemplate,
  stringsAsMarkdownList,
  tlangMarkdown
} from '../../../webmodule-common/other/general/markdown';
import { customElement, state } from 'lit/decorators.js';
import { formatAddress } from '../../../webmodule-common/other/ui/address-editor';
import { getApiFactory } from '../../api/api-injector.js';
import { getQuoteSuppliers, QuoteSupplier } from '../../quotes/quote-service';
import { html } from 'lit';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { map } from 'lit/directives/map.js'
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base.js';
import { tlang } from '../../../webmodule-common/other/language/lang.js';
import { until } from 'lit/directives/until.js'
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store.js';
import { userSecurity } from '../../../webmodule-common/other/api/user-security.js';
import { when } from 'lit/directives/when.js';
import type { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events.js';
import type { Franchisee } from '../../api/dealer-api-interface-franchisee.js';
import type { SupplierApi } from '../../api/supplier-api.js';

@customElement('wm-franchiseesuppliersettingsview')
export class FranchiseeSupplierSettingsView extends PageControlTabWithIndependantSaving {
  supplierApi: SupplierApi = getApiFactory().supplier();

  @state()
  supplierIds: QuoteSupplier[];
  
  constructor() {
    super();
    this.pageFragment = 'supplier';
  }

  get franchisee(): Franchisee {
    return userDataStore.franchisee;
  }

  get isAdmin() {
    return userSecurity().isAdmin() || userSecurity().isSupplier();
  }

  public isDataReadonly(): boolean {
    return !this.isAdmin;
  }

  buttonMenu() {
    return html``;
  }

  private async getSupplierConfig(quoteSupplier: QuoteSupplier){
    const supplierConfig = await this.supplierApi.getSupplierConfiguration({supplierId: quoteSupplier.supplierId});

    if (!supplierConfig)
      return html``;

    return html`
      <div>
        <small>(Last Modified: <webmodule-format-date date="${supplierConfig.configLastModifiedUtc}"></webmodule-format-date>)</small>
        ${when(!isEmptyOrSpace(supplierConfig.termsAndConditionsOfUse), () => html`
          <webmodule-details summary="${tlang`Terms of Use`}">
            ${asMarkdownTemplate(supplierConfig.termsAndConditionsOfUse)}
          </webmodule-details>
        `)}
        
        <webmodule-details summary="${tlang`Quotes`}">
          ${tlangMarkdown`${'ref:supplier-validity-period'}Validity period is **${supplierConfig.validityPeriod}** day(s)`}
          ${tlangMarkdown`${'ref:supplier-lead-period'}Lead period is **${supplierConfig.leadPeriod}** week(s)`}
          ${when(supplierConfig.allowCustomItemRequest, 
            () => html`${tlangMarkdown`${'ref:supplier-custom-request-supported'}**!!special-item-request!!** supported`}`,
            () => html`${tlangMarkdown`${'ref:supplier-custom-request-not-supported'}**!!special-item-request!!** not supported`}`)}
        </webmodule-details>

        ${when(!isEmptyOrSpace(supplierConfig.purchaseOrderTermsAndConditions), () => html`
          <webmodule-details summary="${tlang`Purchase Orders`}">
            ${asMarkdownTemplate(supplierConfig.purchaseOrderTermsAndConditions)}
          </webmodule-details>
        `)}
        
        ${when(supplierConfig.willCallLocations && supplierConfig.willCallLocations.length > 0, () =>
        html`<webmodule-details summary="${tlang`Will Call Locations`}">
          ${tlangMarkdown`${stringsAsMarkdownList(supplierConfig.willCallLocations?.map(x => formatAddress(x.willCallAddress)))}`}
        </webmodule-details>`)}
      </div>
    `;
  }
  
  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();

    this.supplierIds = await getQuoteSuppliers();
  }
  
  public internalDataChanged(): boolean {
    return false;
  }

  public async prepareForSave(): Promise<void> {
    //
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected getCaption(): Snippet {
    return tlang`Supplier's Configuration`;
  }

  protected async internalSaveData(): Promise<boolean> {
    return true;
  }

  protected bodyTemplate(): EventTemplate {
    return html`
        ${map(this.supplierIds, (supplier, _index) => 
          html`
            <div class="row">
              <h2>${supplier.description}</h2>
              ${until(this.getSupplierConfig(supplier), html`<span>Loading...</span>`)}
            </div>
          `)}
    `;
  }
}
