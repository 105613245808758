// eslint-disable-next-line import/named
import { DataBinding, getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import {
  DataEntryPageControlView,
  PageControlChildTab
} from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { EventTemplate, EventVoidAsync, Snippet } from '../../../webmodule-common/other/ui/events';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getApiFactory } from '../../api/api-injector';
import { getQuoteSupplierDisplayName } from '../../quotes/data/quoteSupplierProvider';
import { html } from 'lit';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { PaymentProfile, PaymentProfileCalculation } from '../../api/dealer-api-interface-franchisee';
import { PaymentProfileApi } from '../../api/payment-profile-api';
import { PaymentProfileContainerManager } from '../data/payment-profile-container-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { errorList } from '../../../webmodule-common/other/error-list';
import { PricingModel, pricingModelType } from '../../../webmodule-common/pricing/pricing-model';

export interface PaymentProfileDetailViewOptions {
  paymentProfileManager: PaymentProfileContainerManager;
}

export class PaymentProfileDetailView extends PageControlChildTab {
  paymentProfileManager: PaymentProfileContainerManager;
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  internalId: string;
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  eventRefreshPage: EventVoidAsync | null = null;
  pricingModel: PricingModel = new PricingModel(pricingModelType.margin, 0.99);

  private _supplierName: string = '';

  constructor(owner: DataEntryPageControlView, options: PaymentProfileDetailViewOptions) {
    super(owner);
    this.internalId = getInternalId();

    this.paymentProfileManager = options.paymentProfileManager;

    this.pricingModel.update({ calculation: options.paymentProfileManager.paymentProfile.calculation });

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input, internalId) => `payment-profile-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.paymentProfile),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('name');
    addField('calculation', FieldType.int);
    addField('value', FieldType.float, true);
    addField('quoteTerms');
  }

  protected get paymentProfile(): PaymentProfile {
    return this.paymentProfileManager.paymentProfile;
  }

  async onEnter(): Promise<void> {
    await this.loadOrRefresh();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  public async loadOrRefresh(): Promise<void> {
    await this.paymentProfileManager.needsPaymentProfile();
    this._supplierName = await getQuoteSupplierDisplayName(this.paymentProfileManager.paymentProfile.supplierId);
    this.pricingModel.supplierName = this._supplierName;
    this.requestUpdate();
  }

  public async prepareForSave() {
    this.dataTracker.applyChangeToValue();
  }

  public getValidationErrors(): string[] {
    const validator = errorList();
    validator.assert(!isEmptyOrSpace(this.paymentProfile?.name), tlang`Please provide a %%payment-profile%% name`);
    this.pricingModel.supplierName = this._supplierName;
    this.pricingModel.getValueValidations(this.paymentProfile.value, validator);
    return validator.errors;
  }

  protected getCaption(): Snippet {
    return tlang`%%payment-profile%% Info`;
  }

  protected bodyTemplate(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker);
    forms.immediateBindingUpdate = true;

    return html`
      <form class="frm-client-details form-one-col">
        <div class="row">
          <div>
            ${forms.textRequired('name', tlang`%%payment-profile%%`, 100)}
            ${forms.radioGroup('calculation', PaymentProfileCalculation, tlang`Calculation`, true)}
            ${forms.floatRequired('value', tlang`Value (%)`)}
            ${forms.note('quoteTerms', tlang`%%quote%% Terms and Conditions`, 5000)}
            ${forms.note('estimateTerms', tlang`%%estimate%% Terms and Conditions`, 5000)}
          </div>
        </div>
      </form>
    `;
  }
}
