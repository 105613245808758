// eslint-disable-next-line import/named

import { _lockUIandExecute, lockUIandExecute, setUILockAndExecuteEvent } from '../../webmodule-common/other/ui-lock';
import type { UserPublicInfo } from '../../webmodule-common/other/api/current-user';
import {
  addCurrentUserEventListener,
  getCurrentUser,
  removeCurrentUserEventListener,
  setAfterApplyUserEvent,
  setUserClaims
} from '../../webmodule-common/other/api/current-user';
import { addOrReplaceNFRCCalculation } from '../nfrc/nfrc-calculation';
import type { ResolveURLEntry } from '../../webmodule-common/other/ui/resource-resolver';
import { addURLResolvers, goStaticURL } from '../../webmodule-common/other/ui/resource-resolver';
import {
  addV6BootLoaderEvent,
  clearDealerVersion,
  getQuoteSupplierFromApi,
  v6BootLoader,
  v6bootloaderStale,
  v6Config,
  v6ConfigActiveVersionStr
} from '../v6config/v6config';
import { appConfig, appConfigMenuPageItems } from './app-config';
import type { MenuItem } from '../../webmodule-common/other/app-index';
import { AppIndex } from '../../webmodule-common/other/app-index';
import { applyDebugInformation } from './debug';
import { appOutOfDate } from '../../webmodule-common/other/debug';
import { attachRouter, setRoutes } from '../../webmodule-common/other/router';
import { BlobApiImpl } from '../api/blob-api-impl';
import { claimIdentity, userSecurity } from '../../webmodule-common/other/api/user-security';
import { classMap } from 'lit-html/directives/class-map.js';
import {
  clearCurrentUserFromSession,
  saveCurrentUserIntoSession,
  setUserReloadEvent,
  verifySession
} from '../../webmodule-common/other/user-session-verifier';
import { ClientCache } from './cache-impl/client-cache';
import { ClientPrimaryContactCache } from './cache-impl/client-primarycontact-cache';
import { ContactReferenceCache } from './cache-impl/contact-reference-cache';
import {
  createFranchiseeQuoteProviderData,
  validateAndUpdateFranchiseeQuoteProviderData
} from './quotes/data/franchisee-quote-provider-data';
import { currentUserClaims } from '../../webmodule-common/other/currentuser-claims';
import {
  customElementTry,
  registerComponents,
  registerIconLibrary
} from '../../webmodule-common/components/src/webmodule-components';
import {
  DealerApiCommunications,
  setDealerTokenProvider
} from '../../webmodule-common/other/api/dealer-api-communications';
import { DealerClientAgentApi } from '../api/clientagent-api-implementation';
import { DealerClientApi } from '../api/client-api-implementation';
import { DealerFranchiseeApi } from '../api/franchisee-api-implementation';
import { DevelopmentError, showDevelopmentError } from '../../webmodule-common/other/development-error';
import { displayAllError } from '../../webmodule-common/other/ui/modal-errorhandler';
import { displaySupplierTACNotApprovedMsg, GlobalSupplierNotification } from '../v6config/supplier-services';
import { emptyGuid } from '../../webmodule-common/other/api/guid';
import { EventCancellation } from '../../webmodule-common/other/general/staged-event';
import { firstValidString } from '../../webmodule-common/other/ui/string-helper-functions';
import { injectThemeClasses, isTestSite } from '../../webmodule-common/other/domain';
import { franchiseeComponentRegistry } from './componentRegistry';
import { getApi, getApiFactory, setApiFactory, setApiInjector } from '../api/api-injector';

import type { QuoteSupplier } from '../quotes/quote-service';
import { getQuoteSuppliers, setQuoteSupplierProviderCallback } from '../quotes/quote-service';
import { getUserLock } from '../../webmodule-common/other/api/optimistic-user-lock';
import { GlobalNotificationManager } from '../../webmodule-common/other/ui/icons/icon-notification-signal';
import type { TemplateResult } from 'lit';
import { html } from 'lit';
import { iconRegistry } from '../../webmodule-common/other/ui/icons/register-icons';
import { information } from '../../webmodule-common/other/ui/modal-option';
import { lapsedMinutes, sleep } from '../../webmodule-common/other/general/time';
import { litModalScreenFactoryImpl } from '../../webmodule-common/other/ui/modal-factory-lit';
import { modalScreenFactoryImpl } from '../../webmodule-common/other/ui/modal-factory';
import { NFRCStandardv1Calculation } from '../nfrc/nfrc-standard-v1';
import type { NoSqlDb } from '../../webmodule-common/database/no-sql-db';
import { noSqlDb } from '../../webmodule-common/database/no-sql-db';
import { PaymentProfileApiImplementation } from '../api/payment-profile-api-implementation';
import { PaymentProfileCache } from './cache-impl/payment-profile-cache';
import { performPageLoadLoginVerifications } from '../api/dealer-pageload-verifications';
import { ProjectApiImplementation } from '../api/project-api-implementation';
import { ProjectCache, ProjectPermanentDocumentCache } from './cache-impl/project-cache';
import { ProjectResourceLinkCache } from './cache-impl/project-resource-link';
import { ProjectStateChangeReasonCache } from './cache-impl/project-state-change-reason-cache';
import { PurchaseOrderApiImplementation } from '../api/purchase-order-api-implementation';
import { PurchaseOrderCache } from './cache-impl/purchase-order-cache';
import { PurchaseOrderStateChangeReasonCache } from './cache-impl/purchase-order-state-change-reason-cache';
import { query, state } from 'lit/decorators.js';
import { QuoteApiImpl } from '../api/quote-api-impl';
import { QuoteCache } from './cache-impl/quote-cache';
import { QuoteItemConversationCache } from './cache-impl/quoteitemconversation-cache';
import { QuoteStateChangeReasonCache } from './cache-impl/quote-state-change-reason-cache';
import { registerComponentsLocal } from '../../webmodule-common/other/ui/templatecontrols/component-registry';
import { ResourcesCache } from './cache-impl/resources-cache';
import { responseHandler } from '../api/api-response-handler';
import { runGlobalInit } from '../../webmodule-common/other/app-global-init';
import type { ServiceResponseInvalid, SupplierInfo } from '../../webmodule-common/interop/webmodule-interop';
import { ServiceResponseType } from '../../webmodule-common/interop/webmodule-interop';
import { setCacheRegistry } from './cache-impl/cache-registry';
import { setErrorDialogEventHandler, showError } from '../../webmodule-common/other/ui/show-error';
import { setLitModalScreenFactory } from '../../webmodule-common/other/ui/modal/modal-factory-lit';
import { setModalScreenFactory } from '../../webmodule-common/other/ui/modal/modal-factory';
import {
  setQuoteProviderDataFactory,
  setQuoteProviderDataValidateAndUpgradeEvent
} from '../quotes/data/quote-provider-data';
import { setSupplierInformationCache } from '../quotes/data/quoteSupplierProvider';
import { stagedExecution, WMEventSourceClient } from '../../webmodule-common/other/general/event-source-client';
import { strUserConnectFailed } from '../../webmodule-common/other/api/network-consts';
import { SupplierApiImplementation } from '../api/supplier-api-implementation';
import { SupplierTermsAndConditionsModal } from './supplier/terms-and-conditions-modal';
import { tlang } from '../../webmodule-common/other/language/lang';
import { UserApiImplementation } from '../api/user-api-implementation';
import { userDataStore } from './common/current-user-data-store';
import { UserProfileCache } from './cache-impl/user-profile-cache';
import { webcomponentRegistry } from '../../webmodule-common/other/ui/webcomponent-registry';
import { WMEventSource } from '../api/event-source';
import type { Branch, ConnectedState } from '../api/dealer-api-interface-franchisee';
import type { EventSnippet } from '../../webmodule-common/other/ui/events';
import { getPageController } from '../../webmodule-common/other/ui/pages/page-base';

let currentSupplierNames = '';
let _v6SupplierVersion = '';
let _v6FrameConfigVersion = '';
let _v6FrameConfigApiVersion = '';

function reload() {
  //if this flag exists then we have forced a logoff as part of a PAT login process, so we do not want to redirect or do anything
  if (sessionStorage.getItem('dealer-pat-login')) return;

  // Take us to the home page. this will clear any validations
  // or other issues
  // the home page has code to trigger login if required and
  // page reloading
  goStaticURL('/login');
}

async function connectUserAfterLogin() {
  const reset = async () => {
    userDataStore.clear();

    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    reload();
  };
  const user = getCurrentUser();
  if (user !== null) {
    if (!(await verifySession())) {
      await reset();
      return;
    }

    //DO NOT MAKE ANY CALLS THAT NEED AUTHORIZATION BEFORE CONNECTING THE USER
    //Make any api calls or anything else here that are necessary to be used for the current user
    try {
      /* //loadcoredetails will do this
            const valid =
                await DealerApiCommunications.verifyUserAndSetSecurityClaims(
                    'api/LicenseServer/verifyuser'
                );
*/
      const state = await getApi().post<ConnectedState>('api/Franchisee/ConnectUser', {});
      if (!state?.connected) throw new Error(strUserConnectFailed);

      //this will update the user claims security before we rebind the router.
      await userDataStore.loadCoreDetailsAfterLogin();
      saveCurrentUserIntoSession();
    } catch (e) {
      await showDevelopmentError(e as Error);
      await reset();
    }
    await reloadSuppliersAfterLogin();
  } else {
    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    userDataStore.clear();
    reload();
    //we always want to login again
  }
}

function rebindRouter() {
  const config = appConfig();
  setRoutes(config.routes);
  addURLResolvers(
    config.routes
      .filter(x => x.resolveUrl !== undefined)
      .map(x => {
        return x.resolveUrl as ResolveURLEntry;
      })
  );
}

// this is our concrete top level application, everything else should be generic
@customElementTry('franchisee-app-index')
export class FranchiseeAppIndex extends AppIndex {
  protected noSqlDb: NoSqlDb = noSqlDb;
  // Do not remove below line as this will stop icons from working on our site.
  protected icons = iconRegistry;
  protected webcomponents = webcomponentRegistry;
  protected franchiseeComponents = franchiseeComponentRegistry;
  @query('#main-body')
  private mainBody!: HTMLElement;
  private _supplierOnlineCheckTimer?: NodeJS.Timeout;
  @state()
  private _lastCheckedSupplierOnline?: Date;
  @state()
  private _supplierOnline = GlobalSupplierNotification.getDefaultInstance()?.online;
  @state() private _supplierOfflineMessage = GlobalSupplierNotification.getDefaultInstance()?.offlineMessage;

  constructor() {
    super();
    rebindRouter();

    registerIconLibrary('system', {
      resolver: name => `/assets/icons/${name}.svg`
    });

    registerIconLibrary('bootstrap', {
      resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/icons/${name}.svg`
    });

    registerIconLibrary('fa', {
      resolver: name => {
        const filename = name.replace(/^fa[rbs]-/, '');
        let folder = 'regular';
        if (name.startsWith('fas-')) folder = 'solid';
        if (name.startsWith('fab-')) folder = 'brands';
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.2/svgs/${folder}/${filename}.svg`;
      },
      mutator: svg => svg.setAttribute('fill', 'currentColor')
    });

    this.userDisplayName = getCurrentUser()?.friendlyName;
  }

  async userStateChanged(_user: UserPublicInfo | null) {
    if (appOutOfDate()) return;
    const user = getCurrentUser();
    if (user !== null) {
      await this.updateUIAspectsAfterLogin();
      WMEventSource.getInstance().connectUser();
    } else {
      this.userDisplayName = '';
      WMEventSource.getInstance().disconnect();
    }
  }

  generateBuildNumberText(): TemplateResult {
    const copyEvent = async () => {
      function parts(val: string[], from, count: number): string {
        //Supplier Ver "10.0.13.31.30.380481012"
        return val.slice(from, from + count).join('.');
      }

      function supplierDetails(suppinfo: SupplierInfo, buildnumber: string) {
        const nums = suppinfo.version.split('.');
        return `
### ${tlang`%%supplier%% "${suppinfo.description}`}"

+ ${tlang`Macros "${parts(nums, 0, 3)}`}"
+ ${tlang`DB Instance Version "${parts(nums, 3, 1)}`}"
+ ${tlang`DB Pricing Version "${parts(nums, 4, 1)}`}"
+ ${tlang`V6"${parts(nums, 5, 1)}`}"
+ ${tlang`API "${firstValidString(_v6FrameConfigApiVersion, buildnumber)}`}"
------------------------------------------------------

            `;
      }

      const v6Suppliers = (await v6Config().suppliers(true)) ?? [];
      const text = `# Dealer's Module (SoftTech dealer CPQ)
      
+ ${tlang`Location`} "${window.location.hostname}"     
+ ${tlang`Dealer Build "${this.buildNumber}`}"
+ ${tlang`Frame Config "${_v6FrameConfigVersion}`}"
      
## Supplier Details
${v6Suppliers.map(x => supplierDetails(x, this.buildNumber ?? '')).join('\n')}

            `;
      navigator?.clipboard?.writeText(text);
      await information(text, tlang`Copied to clipboard`);
      //fireQuickInformationToast(tlang`Version Details have been copied to the clipboard`, 6000);
    };

    function labelSpan(label: string, value: string) {
      return html`<span class="fw-bolder me-2">${label}</span><span class="me-2">${value}</span>`;
    }

    return html`${labelSpan(
        tlang`Build`,
        `${this.buildNumber ?? ''} | ${_v6SupplierVersion} | ${_v6FrameConfigVersion} `
      )} <span class="ms-2 me-2"><icon-copy @click=${copyEvent}></icon-copy></span>`;
  }

  serviceOnlineTemplate() {
    return this._supplierOnline
      ? html``
      : html` <div class="supplier-offline-alert">
          <span>${this._supplierOfflineMessage}</span>
        </div>`;
  }

  connectedCallback(): void {
    super.connectedCallback();
    const gsn = GlobalSupplierNotification.getDefaultInstance();
    gsn?.addEventListenter(this._supplierStatusCallback);
    this._supplierOnline = gsn?.online;
    this._supplierOfflineMessage = gsn?.offlineMessage;

    addCurrentUserEventListener(this);
    setUserReloadEvent(async () => {
      const user = getCurrentUser();
      if (user) {
        await userDataStore.reLoadFranchisee();
        await this.updateUIAspectsAfterLogin();
      } else {
        userDataStore.clear();
      }

      this.requestUpdate();
    });
    if (gsn) gsn.extraText = tlang`Testing connection every 5 minutes.`;
    this._supplierOnlineCheckTimer = setTimeout(this._supplierOnlineCheckEvent, 60000);
  }

  disconnectedCallback(): void {
    clearTimeout(this._supplierOnlineCheckTimer);
    this._supplierOnlineCheckTimer = undefined;
    GlobalSupplierNotification.getDefaultInstance()?.remEventListenter(this._supplierStatusCallback);
    super.disconnectedCallback();
    removeCurrentUserEventListener(this);
  }

  userNameMenuContent(): unknown {
    return html` <webmodule-icon library="fa" name="fas-user" slot="prefix"></webmodule-icon
      >${this.userDisplayName?.trim()}`;
  }

  aboveUserNameTemplate(): unknown {
    const branchName = userDataStore.loaded ? userDataStore.defaultBranch.name.trim() : '';

    return userDataStore.loaded
      ? html`
          <webmodule-dropdown placement="bottom">
            <webmodule-button variant="text" slot="trigger" caret>
              <webmodule-icon library="fa" name="fas-building" slot="prefix"></webmodule-icon>
              ${branchName}
            </webmodule-button>
            <webmodule-menu> ${this.customBranchMenuElements()}</webmodule-menu>
          </webmodule-dropdown>
        `
      : html``;
  }

  globalSupportInformation(): string | TemplateResult {
    const cu = getCurrentUser();
    if (!cu) return '';
    const cc = currentUserClaims();
    if (cc.isCynclyStaff) {
      return cc.isAgent // cyncly staff can log in as a dealer or supplier depending on support needed
        ? `CYNCLY Support AS SUPPLIER connected to "${
            (cu as any).tenantName
          }", Using Supplier(s) ${currentSupplierNames}`
        : `CYNCLY Support AS DEALER connected to "${(cu as any).tenantName}", Using Supplier ${currentSupplierNames}`;
    } else if (cc.isAgent) {
      return tlang`%%Supplier%% Support Connected to "${(cu as any).tenantName}"`;
    }
    return '';
  }

  customUserMenuElements(): TemplateResult {
    return html``;
  }

  customBranchMenuElements(): TemplateResult {
    const doNothing = (e: Event) => {
      e.stopImmediatePropagation();
      e.preventDefault();
    };
    const switchBranchEvent = (branch: Branch) => () => {
      this.changeBranch(branch);
    };
    const branchTemplate = (branch: Branch) => {
      const isSelected = branch.id === userDataStore.defaultBranch.id;
      return html`
        <webmodule-menu-item
          class="${classMap({
            'fw-bolder': isSelected
          })}"
          @click=${switchBranchEvent(branch)}
        >
          <webmodule-icon
            class="${classMap({
              'text-primary': isSelected
            })}"
            slot="prefix"
            library="fa"
            name="fas-caret-right"
          ></webmodule-icon>
          ${branch.name}
        </webmodule-menu-item>
      `;
    };
    const switchBranchTemplate = () => {
      return userDataStore
        .getUserBranches()

        .map(x => branchTemplate(x));
    };
    if (!getCurrentUser() || !userDataStore.loaded) return html``;
    if (userSecurity().claimIsTrue(claimIdentity.allBranches) && userDataStore.getUserBranches().length > 1) {
      return html`
        <webmodule-menu-label>${tlang`!!branch!!`}</webmodule-menu-label>
        ${switchBranchTemplate()}
        <webmodule-divider></webmodule-divider>
      `;
    } else
      return html` <webmodule-menu-label>${tlang`!!branch!!`}</webmodule-menu-label>

        <webmodule-menu-item @click=${doNothing}>
          <webmodule-icon class="text-primary" slot="prefix" library="fa" name="fas-caret-right"></webmodule-icon>
          ${userDataStore.defaultBranch.name}
        </webmodule-menu-item>
        <webmodule-divider></webmodule-divider>`;
  }

  async changeBranch(branch: Branch) {
    await lockUIandExecute(async () => {
      if (!(await getPageController().pagesCanClose())) return;
      goStaticURL('');
      await sleep(300);
      userDataStore.defaultBranch = branch;
      await userDataStore.setV6OverrideKey();

      //the line below is executed in the line above

      await getPageController().disposeAndRefresh();
      this.requestUpdate();
    });
  }

  async disconnectUser() {
    await getPageController().disposeAll();
    try {
      await getApi().post<ConnectedState>('api/Franchisee/DisconnectUser', {});
      setUserClaims({});
    } catch {
      console.log(`Error disconnecting user`);
    }
    return true;
  }

  public updateLogoTemplate() {
    if (this.outOfDate) return;
    const api = getApi();
    try {
      const logoURl = isTestSite()
        ? api.fullUrl(`api/file/${userDataStore.franchisee.negativeLogoVirtualPath}`)
        : api.fullUrl(`api/file/${userDataStore.franchisee.positiveLogoVirtualPath}`);
      this.logoTemplate = userDataStore.franchisee.positiveLogoVirtualPath
        ? html`<img src=${logoURl} crossorigin="anonymous" class="img-fluid" alt="Brand" width="90" />`
        : html` <svg width="1" height="1"></svg>`;
    } catch {
      //may be doing this after a failed login or out of date issue
    }
  }

  protected getMenuItems(): MenuItem[] {
    return appConfigMenuPageItems();
  }

  protected firstUpdated() {
    attachRouter(this.mainBody);
    let interval;
    const verifyUser = async () => {
      clearInterval(interval);
      await verifySession();
      interval = setInterval(verifyUser, 500);
    };
    interval = setInterval(verifyUser, 500);
    injectThemeClasses();
  }

  private _supplierOnlineCheckEvent = async () => {
    const gsn = GlobalSupplierNotification.getDefaultInstance();
    if (!gsn) return;
    if (!gsn.online) {
      if (!this._lastCheckedSupplierOnline || lapsedMinutes(this._lastCheckedSupplierOnline) > 5) {
        const suppliers = await getQuoteSuppliers(true);
        if (suppliers.length > 0 && suppliers[0].online) {
          this._lastCheckedSupplierOnline = undefined;
        } else this._lastCheckedSupplierOnline = new Date();
      }
      gsn.extraText = tlang`Testing connection every 5 minutes. Last Check ${this._lastCheckedSupplierOnline?.toLocaleTimeString()}  `;
    } else this._lastCheckedSupplierOnline = undefined;
    this._supplierOnlineCheckTimer = setTimeout(this._supplierOnlineCheckEvent, 60000);
  };

  private _supplierStatusCallback = () => {
    try {
      this._supplierOnline = GlobalSupplierNotification.getInstance().online;
      this._supplierOfflineMessage = GlobalSupplierNotification.getInstance().offlineMessage;
    } catch {
      /* empty */
    }
  };

  private async updateUIAspectsAfterLogin() {
    await userDataStore.loadCoreDetailsAfterLogin();
    this.updateLogoTemplate();
    this.userDisplayName = getCurrentUser()?.friendlyName;
    this.requestUpdate();
  }
}

async function processSupplierTAC(suppliers: QuoteSupplier[]) {
  for (let i = 0; i < suppliers.length; i++) {
    const supplier = suppliers[i];
    const tacApproved = userDataStore.supplierTACApproved(supplier.supplierId);
    if (!tacApproved) {
      const claims = currentUserClaims();
      if (claims.isAgent || claims.isCynclyStaff || claims.trueClaim('non-tenant-user'))
        await displaySupplierTACNotApprovedMsg(supplier);
      else await approveTACforSupplier(supplier);
    }
  }
}

async function reloadSuppliersAfterLogin() {
  clearDealerVersion();
  await v6BootLoader();
  applyQuoteSupplierProviderCallback();
  const suppliers = await getQuoteSuppliers(true);
  await processSupplierTAC(suppliers);
  currentSupplierNames = suppliers.map(x => `[${x.description}]`).join(', ');
}

function applyQuoteSupplierProviderCallback() {
  let _suppliers: QuoteSupplier[] | null = null;
  setQuoteSupplierProviderCallback(async (refreshList: boolean) => {
    const bootloader = await v6BootLoader();
    if (bootloader) {
      const v6Suppliers = (await v6Config().suppliers(refreshList)) ?? [];

      _v6SupplierVersion = v6Suppliers.length === 1 ? v6Suppliers[0].version : '';
      _v6FrameConfigVersion = v6ConfigActiveVersionStr();
      _v6FrameConfigApiVersion = globalThis.dealerConfiguration.v6apiBuildNumberDisplay;

      const results = v6Suppliers.map(s => {
        const qs: QuoteSupplier = {
          supplierId: s.id,
          description: s.description,
          online: s.online,
          offlineCause: s.offlineCause
        };
        return qs;
      });
      results.forEach(si => {
        GlobalSupplierNotification.getInstance(si.supplierId).setState(si);
      });
      return results;
    } else {
      if (_suppliers !== null && !refreshList) return _suppliers;
      _suppliers = (await getQuoteSupplierFromApi()) ?? [];
      _suppliers.forEach(si => {
        GlobalSupplierNotification.getInstance(si.supplierId).setState(si);
      });
      return _suppliers;
    }
  });
}

const bootloaderStaleEvent = (gsn: GlobalSupplierNotification) => {
  if (!gsn.online) v6bootloaderStale();
  else
    v6BootLoader().then(value => {
      console.log(`V6Bootloader: ${value}`);
    });
};
const afterV6BootLoader = async (): Promise<void> => {
  if (!userDataStore.loaded) throw new DevelopmentError('cant perform bootloading without user data');
  await userDataStore.setV6OverrideKey();
};
(function () {
  globalThis.dealerPrepUserData = async updateNote => {
    try {
      updateNote('Initialize Frame Configuration Service');
      addV6BootLoaderEvent(afterV6BootLoader);
      await v6BootLoader();
      //bootloader has already been run.

      applyQuoteSupplierProviderCallback();
      //we want to enforce that the system the user connects to is fully up to date. this is a
      //simple call to the v6 systems to check their version, and will ensure the system resets info
      //as needed. This is also required for the quotes page, so we know if we are a multi supplier view or not
      updateNote('Fetching Supplier Information');
      const suppliers = await getQuoteSuppliers(true);
      suppliers.forEach(s => {
        GlobalSupplierNotification.getInstance(s.supplierId).addEventListenter(bootloaderStaleEvent);
      });

      if (userDataStore.loaded) {
        await processSupplierTAC(suppliers);
        currentSupplierNames = suppliers.map(x => `[${x.description}]`).join(', ');
      }
    } catch {
      console.log('could not load suppliers');
    }
  };

  globalThis.dealerBuildApplicationBindings = async () => {
    applyDebugInformation();
    setUILockAndExecuteEvent(_lockUIandExecute);
    registerComponents();
    registerComponentsLocal();

    setLitModalScreenFactory(litModalScreenFactoryImpl);
    setModalScreenFactory(modalScreenFactoryImpl);
    setAfterApplyUserEvent(connectUserAfterLogin);
    registerEventSourceClients();
    setSupplierInformationCache({
      getSupplierDisplayName: async (supplierId: string) => {
        const suppliers = await getQuoteSuppliers(false);
        return suppliers.find(x => x.supplierId === supplierId)?.description ?? tlang`%%supplier%%`;
      }
    });
    setDealerTokenProvider(() => getUserLock());

    const errorDialogEventHandler = async (item: ServiceResponseInvalid | Error, title: EventSnippet) =>
      await displayAllError(title, item);
    setErrorDialogEventHandler(errorDialogEventHandler);

    //Bind data provider information inot the generic
    setQuoteProviderDataFactory(createFranchiseeQuoteProviderData);
    setQuoteProviderDataValidateAndUpgradeEvent(validateAndUpdateFranchiseeQuoteProviderData);

    addOrReplaceNFRCCalculation(new NFRCStandardv1Calculation());
    /*
      //any class using the async construct pattern will be assurred of a valid login
    //before the afterconstruction is called.
    //the new workflow does not require this anymore
    setBeforeConstructionEvent(async () => await userDataStore.loadCoreDetails());
*/
    setApiFactory({
      quote: () => new QuoteApiImpl(getApi()),
      blob: () => new BlobApiImpl(getApi()),
      client: () => new DealerClientApi(getApi()),
      project: () => new ProjectApiImplementation(getApi()),
      franchisee: () => new DealerFranchiseeApi(getApi()),
      paymentProfile: () => new PaymentProfileApiImplementation(getApi()),
      purchaseOrder: () => new PurchaseOrderApiImplementation(getApi()),
      supplier: () => new SupplierApiImplementation(getApi()),
      clientAgent: () => new DealerClientAgentApi(getApi()),
      user: () => new UserApiImplementation(getApi())
    });

    let _commSingleton: DealerApiCommunications | undefined;
    const apiInjecterEvent = () => {
      if (!_commSingleton)
        _commSingleton = new DealerApiCommunications('', responseHandler, () => {
          //Redirect to home page, next query will force a login to occur
          window.location.href = '/login';
        });
      return _commSingleton;
    };

    //Dependency inject an api for the entire application
    setApiInjector(apiInjecterEvent);
    setCacheRegistry(() => {
      const api = getApi();
      return {
        client: new ClientCache(api),
        quoteItemConversation: new QuoteItemConversationCache(api),
        contact: new ContactReferenceCache(api),
        primaryContact: new ClientPrimaryContactCache(api),
        paymentProfile: new PaymentProfileCache(api),
        project: new ProjectCache(api),
        projectPermanentDocuments: new ProjectPermanentDocumentCache(api),
        quote: new QuoteCache(api),
        resource: new ResourcesCache(api),
        userProfile: new UserProfileCache(api),
        purchaseOrder: new PurchaseOrderCache(api),
        projectResourceLink: new ProjectResourceLinkCache(api),
        quoteStateReason: new QuoteStateChangeReasonCache(api),
        projectStateReason: new ProjectStateChangeReasonCache(api),
        purchaseOrderStateReason: new PurchaseOrderStateChangeReasonCache(api)
      };
    });

    setQuoteSupplierProviderCallback(async (_refreshList: boolean) => {
      return [
        {
          supplierId: emptyGuid,
          description: tlang`Loading`,
          online: false
        }
      ];
    });

    window.addEventListener('unhandledrejection', event => {
      if (event.reason.message.includes('Vaadin')) {
        return;
      }
      console.error(event.reason.stack);
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      return;
      showError(
        {
          responseType: ServiceResponseType.Error,
          responseTypeCaption: tlang`unhandled error`,
          responseError: {
            message: event.reason.message,
            stackTrace: event.reason.stackTrace
          }
        },
        () => tlang`Unhandled Error inside a promise occurred`
      );
    });

    runGlobalInit();
  };
})();

function registerEventSourceClients() {
  //configure main event source.
  WMEventSourceClient.getInstance(undefined, {
    onlineCallback: value => {
      GlobalNotificationManager.getInstance().online = value;
      if (globalThis.dealerConfiguration.apiHost === globalThis.dealerConfiguration.v6apiHost)
        GlobalNotificationManager.getInstance('v6config').online = value;
    },
    afterOnlineEvent: () => {
      WMEventSource.afterConnect();
    },
    url: globalThis.dealerConfiguration.apiHost
  });

  const supplierStatusEvent = stagedExecution({
    event: () => {
      //if we are getting this event, something was rebooted.
      v6bootloaderStale();
      globalThis.v6config = globalThis.v6config ?? {};
      globalThis.v6config.forceLoad = true;
      getQuoteSuppliers(true).finally(() => {
        globalThis.v6config.forceLoad = false;
      });
    },
    cancelToken: new EventCancellation(),
    testInterval: 250,
    threshold: 1000
  });
  //add an event listener to v6 status events
  WMEventSourceClient.getInstance().addEventListener(WMEventSource.v6configServiceStatus, supplierStatusEvent);

  //if we are running a seperate service for v6 events bind in a 2nd listner
  if (globalThis.dealerConfiguration.apiHost !== globalThis.dealerConfiguration.v6apiHost) {
    //running a seperate server so lets do a passthrough event
    WMEventSourceClient.getInstance('v6config', {
      onlineCallback: value => {
        GlobalNotificationManager.getInstance('v6config').online = value;
      },
      afterOnlineEvent: () => {
        //WMEventSource.afterConnect();
      },
      url: globalThis.dealerConfiguration.v6apiHost
    }).addEventListener('', (data, eventName) => {
      //we dont want seperate listeners.. we will pass everything through to the main system
      WMEventSourceClient.pushEventToDefault(eventName, data);
    });
  }
}

async function approveTACforSupplier(supplier: QuoteSupplier) {
  const r = await getApiFactory().supplier().getSupplierTAC({ supplierId: supplier.supplierId });
  if (!r) {
    await information(tlang`The %%supplier%% (${supplier.description}) Terms and Conditions cannot be retrieved at this time. Please try refreshing this page. 
        Contact support if no progress is made.`);
    return false;
  }
  const modal = new SupplierTermsAndConditionsModal(supplier, r?.supplierTermsAndConditionsOfUse);
  await modal.showModal();
  return modal.ok;
}

if (!performPageLoadLoginVerifications) console.log('missing page loader');
