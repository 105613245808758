// eslint-disable-next-line import/named
import { awaitableTemplate } from '../../../../webmodule-common/other/ui/template-processor.js';
import { BranchQuoteSupportStatus } from '../../../api/dealer-api-interface-franchisee.js';
import { classMap } from 'lit/directives/class-map.js';
import { constructAsync } from '../../../../webmodule-common/other/async-constructor.js';
import { consume } from '@lit/context';
import {
  currentUserClaims,
  isCynclyStaff,
  isSupplierAgent
} from '../../../../webmodule-common/other/currentuser-claims.js';
import { customElement, property, query, state } from 'lit/decorators.js';
import { DataBinding } from '../../../../webmodule-common/other/ui/databinding/databinding.js';
import {
  DataTracker,
  DynamicValueBinder,
  FieldType
} from '../../../../webmodule-common/other/ui/databinding/data-tracker.js';
import { DevelopmentError } from '../../../../webmodule-common/other/development-error.js';
import { fireQuickWarningToast } from '../../../../webmodule-common/other/toast-away.js';
import {
  firstValidString,
  isEmptyOrSpace,
  sameText
} from '../../../../webmodule-common/other/ui/string-helper-functions.js';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant.js';
import { FrameValidationType } from '../../../../webmodule-common/interop/webmodule-interop.js';
import { getApiFactory } from '../../../api/api-injector.js';
import { getBuyInDetailsTemplate, getFrameDetailsTemplate } from '../../../frames/ui.js';
import { getQuoteSupplierDisplayName } from '../../../quotes/data/quoteSupplierProvider.js';
import {
  getQuoteTitleStr,
  internalQuoteItemFields,
  isDefaultShipping,
  isFrame,
  isFreehand,
  isShipping,
  isSpecialItem,
  isSSI,
  quoteItemThumbSrc
} from '../../../quotes/data/quote-helper-functions.js';
import {
  getV6FrameForContainer,
  getV6QuoteItemForContainer,
  getV6QuoteItemNFRCData,
  isV6
} from '../../../quotes/data/v6/helper-functions.js';
import { getV6QuoteItemDisplayHeightFirst, getV6QuoteItemFrameDimensions } from '../../../v6config/v6-ui.js';
import { highestValidationRank, rankToValidationType } from '../../../v6config/validation-rank.js';
import { html, LitElement, nothing } from 'lit';
import { imagePreviewContext } from '../../../../webmodule-common/other/context/imagePreview.js';
import { isDebugMode } from '../../../../webmodule-common/other/debug.js';
import { isValidV6ConfigVersion, v6SupportsVersion, v6Util, v6VersionMap } from '../../../v6config/v6config.js';
import { lang, tlang } from '../../../../webmodule-common/other/language/lang.js';
import {
  money,
  moneyToTemplateResult,
  number2dpToHtml,
  number4dpToHtml
} from '../../../../webmodule-common/other/currency-formatter.js';
import { monitor } from '../../../../webmodule-common/other/monitor.js';
import { nfrcCalculator } from '../../../nfrc/nfrc-calculation.js';
import { PricingModel, pricingModelType } from '../../../../webmodule-common/pricing/pricing-model.js';
import { quoteItemAction } from '../../../quotes/data/events.js';
import { QuoteItemsView } from '../../../quotes/views/quote-items-view.js';
import { QuotePriceCalculation, QuoteState } from '../../../api/dealer-api-interface-quote.js';
import { saveWithIndicator } from '../../../../webmodule-common/other/save-workflow.js';
import { supplierItemContentTypeDisplay } from '../../../quotes/data/supplier-quote-item-content-type.js';
import { tryAsNumberString } from '../../../../webmodule-common/other/number-utilities.js';
import { userDataStore } from '../../common/current-user-data-store.js';
import { userLocalSettings } from '../../../../webmodule-common/other/context/UserSettingsContext.js';
import { V6BomViewer } from './v6/franchisee-v6-bom-viewer.js';
import { when } from 'lit/directives/when.js';
import WebmoduleIcon from '../../../../webmodule-common/components/src/components/icon/icon.js';
import type { EventTemplate } from '../../../../webmodule-common/other/ui/events.js';
import type {
  EventValueGetter,
  EventValueSetter
} from '../../../../webmodule-common/other/ui/databinding/data-tracker.js';
import type {
  FrameBuyInItem,
  NFRCColumn,
  NFRCOptions,
  NFRCRowResult,
  NFRCRowValues,
  NFRCValue,
  QCQuoteValues,
  QCValue
} from '../../../../webmodule-common/interop/webmodule-interop.js';
import type { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager.js';
import type { ImagePreview } from '../../../../webmodule-common/other/context/imagePreview.js';
import type { IUserSettings } from '../../../../webmodule-common/other/context/IUserSettings.js';
import type { NFRCCalculationResult } from '../../../nfrc/nfrc-calculation.js';
import type { PropertyValues } from 'lit';
import type { QuoteApi } from '../../../api/quote-api.js';
import type { QuoteContainerManager } from '../../../quotes/data/quote-container.js';
import type { QuoteItem } from '../../../api/dealer-api-interface-quote.js';
import type { QuoteItemContainer } from '../../../quotes/data/quote-item-container.js';
import type { QuoteItemsViewOptions } from '../../../quotes/views/quote-items-view.js';
import type {
  ResultGetSupplierPricingRule,
  TableColumnConfiguration,
  TaxRate
} from '../../../api/dealer-api-interface-franchisee.js';
import type { TemplateResult } from 'lit-html';
import type { V6FranchiseeQuoteProviderData } from '../data/franchisee-quote-provider-data.js';
import type {
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebmoduleSelectEvent,
  WebModuleTableItemMove
} from '../../../../webmodule-common/components/src/webmodule-components.js';

interface QuoteItemUserPageSettings {
  isClientFacingView: boolean;
  clientFacingConfiguration?: TableColumnConfiguration[];
  internalConfiguration?: TableColumnConfiguration[];
}

class NFRCDataCache {
  nfrcOptions: () => NFRCOptions;
  private nfrcColCache: NFRCRowResult[] = [];
  private nfrcRowDataCache: NFRCRowValues[] = [];

  constructor(nfrcOptions: () => NFRCOptions) {
    this.nfrcOptions = nfrcOptions;
  }

  public nfrcGetRowValues(id: string, v6QuoteItem: object): NFRCRowValues {
    let nfrcRowValues = this.nfrcRowDataCache.find(x => x.id === id);
    if (!nfrcRowValues) {
      const nfrcData = getV6QuoteItemNFRCData(this.nfrcOptions().calculationName, v6QuoteItem);
      nfrcRowValues = { id, nfrcData };
      this.nfrcRowDataCache.push(nfrcRowValues);
    }
    return nfrcRowValues;
  }

  public nfrcColumnValue(quoteItemContainer: QuoteItemContainer, publishedCode: string): number {
    let cacheItem = this.nfrcColCache.find(x => x.id == quoteItemContainer.item.id);
    if (!cacheItem) {
      const data = getV6QuoteItemForContainer(quoteItemContainer);
      if (!data) return NaN;
      const nfRCData = this.nfrcGetRowValues(quoteItemContainer.item.id, data);
      cacheItem = nfrcCalculator(this.nfrcOptions().calculationName).nfrcColumnCalculation(nfRCData);
      this.nfrcColCache.push(cacheItem);
    }

    const codeValue = cacheItem.values.find(x => sameText(x.name, publishedCode));
    return codeValue?.value ?? NaN;
  }

  public nfrcGetResults(quoteContainerManager: QuoteContainerManager): NFRCCalculationResult {
    const input: NFRCRowValues[] = [];
    //this should have been called first to ensure loaded
    //quoteContainerManager.needsQuoteItems

    //scan and get cached or load cached data for each row item
    quoteContainerManager.container.items?.forEach(item => {
      const container = quoteContainerManager.quoteItemContainer(item.id);
      if (isFrame(item)) {
        const v6Item = getV6QuoteItemForContainer(container);
        if (!v6Item) return;
        const row: NFRCRowValues = this.nfrcGetRowValues(item.id, v6Item);
        input.push(row);
      }
    });

    return nfrcCalculator(this.nfrcOptions().calculationName).nfrcCalculation(input);
  }

  public clear() {
    this.nfrcColCache = [];
    this.nfrcRowDataCache = [];
  }
}

class SettingsManager {
  private readonly configSaveKey = 'quote-item-summary-user-configuration';
  private userSettingsContext: IUserSettings = userLocalSettings;

  //This is set in the loadSetting that is called from constructor
  private _settings!: QuoteItemUserPageSettings;
  private _activeConfig?: TableColumnConfiguration[];
  private _systemDefault: TableColumnConfiguration[];
  private _supplierOverride: TableColumnConfiguration[];

  constructor(systemDefaults: TableColumnConfiguration[], supplierOverride: TableColumnConfiguration[]) {
    this._systemDefault = systemDefaults;
    this._supplierOverride = supplierOverride;

    this.loadSettings();
  }

  public get getSettings(): QuoteItemUserPageSettings {
    return this._settings;
  }

  public get getIsClientFacing(): boolean {
    return this._settings.isClientFacingView;
  }

  public get getActiveConfiguration() {
    if (this._activeConfig) return this._activeConfig;
    //This contains all columns available
    let cols = this._systemDefault;

    cols = cols.map(x => {
      const override = this.getIsClientFacing && internalQuoteItemFields.find(y => y == x.code);

      if (override) {
        return { ...x, canSwitch: false, defaultDisplay: false };
      }

      return x;
    });

    // Override system default with supplier defaults
    if (this._supplierOverride) {
      cols = cols.map(x => {
        const override = this._supplierOverride.find(y => y.code == x.code);

        //Supplier should always be able to toggle options, ie restrictions apply to dealer user.
        if (override) {
          const canUserChange = currentUserClaims().isAgent || override.canSwitch;
          return { ...x, canSwitch: canUserChange, defaultDisplay: override.defaultDisplay };
        }

        return x;
      });
    }

    const userOverrides = this.getIsClientFacing
      ? this._settings.clientFacingConfiguration
      : this._settings.internalConfiguration;

    if (userOverrides) {
      cols = cols.map(x => {
        const override = userOverrides.find(y => y.code == x.code && x.canSwitch);

        if (override) {
          return { ...x, defaultDisplay: override.defaultDisplay };
        }

        return x;
      });
    }
    this._activeConfig = cols;
    return this._activeConfig;
  }

  public updateConfiguration(newSettings: TableColumnConfiguration | TableColumnConfiguration[]): void {
    if (this._settings.isClientFacingView) {
      this._settings.clientFacingConfiguration = this.overrideConfiguration(
        this._settings.clientFacingConfiguration,
        newSettings
      );
    } else {
      this._settings.internalConfiguration = this.overrideConfiguration(
        this._settings.internalConfiguration,
        newSettings
      );
    }
    this.saveSettings();
  }

  public toggleColumnDisplay(code: string) {
    const config = this.getActiveConfiguration.find(x => x.code == code);

    if (config) {
      config.defaultDisplay = !config?.defaultDisplay;

      this.updateConfiguration(config);
      this.saveSettings();
    }
  }

  public toggleClientFacingView() {
    this._settings.isClientFacingView = !this._settings.isClientFacingView;
    this.saveSettings();
  }

  private overrideConfiguration(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    currentConfig: TableColumnConfiguration[] = [],
    newSettings: TableColumnConfiguration | TableColumnConfiguration[]
  ): TableColumnConfiguration[] {
    if (!Array.isArray(newSettings)) {
      newSettings = [newSettings];
    }

    const currentMap = new Map(currentConfig.map(x => [x.code, x]));
    newSettings.forEach(x => currentMap.set(x.code, x));

    return Array.from(currentMap.values());
  }

  private saveSettings() {
    this.userSettingsContext.saveSetting<QuoteItemUserPageSettings>(this.configSaveKey, this._settings);
    this._activeConfig = undefined;
  }

  private loadSettings() {
    this._settings =
      this.userSettingsContext.getSetting<QuoteItemUserPageSettings>(this.configSaveKey) ??
      new (class implements QuoteItemUserPageSettings {
        clientFacingConfiguration: TableColumnConfiguration[] = [];
        internalConfiguration: TableColumnConfiguration[] = [];
        isClientFacingView = true;
      })();
  }
}

@customElement('franchisee-quote-item-table')
export class FranchiseeQuoteItemTable extends LitElement {
  quoteApi: QuoteApi = getApiFactory().quote();
  @query('#quote-items-table')
  table?: WebModuleLitTable;
  @property({
    type: Array,
    hasChanged(newVal: object, oldVal: object) {
      return newVal !== oldVal;
    }
  })
  public columnConfiguration: TableColumnConfiguration[] = [];
  @property({ type: Object })
  public quoteManager!: FranchiseeQuoteContainerManager;
  @property({ type: Object })
  public nfrcOptions?: NFRCDataCache;
  @consume({ context: imagePreviewContext })
  @property({ attribute: false })
  public imagePreview?: ImagePreview;

  @state()
  private _columnsData: WebModuleLitTableColumnDef[] = [];
  @state()
  private _data: QuoteItem[] = [];
  @state()
  private _columns: WebModuleLitTableColumnDef[] = [];
  @state()
  private _expandedOptions = false;

  dispatchCustom(values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };

    this.dispatchEvent(new CustomEvent(`wm-quote-item-action`, options));
  }

  @monitor('columnConfiguration', { delayUntilFirstUpdate: true })
  public updateCols() {
    const colDisplay = this._columnsData.map(x => {
      const config = this.columnConfiguration.find(y => y.code == x.id);

      return { visible: config?.defaultDisplay, ...x };
    });

    this._columns = [
      ...colDisplay,
      ...[
        {
          title: html` <webmodule-icon library="fa" name="fas-bars"></webmodule-icon>`,
          classes: 'colpxmax-48 item-menu',
          fieldName: 'xx',
          displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
            const rowItem = item as QuoteItem;

            return this.ellipsisMenu(rowItem);
          }
        }
      ]
    ];
  }

  public async refreshData() {
    await this.quoteManager.needsQuoteItems(false);
    await this.quoteManager.needsQuoteSupport(true);
    await this.quoteManager.performItemSorting();

    if (this.quoteManager.container.items) {
      this._data = [...this.quoteManager.container.items];
    } else {
      this._data = [];
    }
    this.requestUpdate();
  }

  @monitor('_expandedOptions', { delayUntilFirstUpdate: true })
  public async handleExpandCollapse() {
    if (!this.table) return;

    this.quoteManager.container.items?.forEach(x => {
      const rowItem = x;

      const isShown = this.table!.isExpanded(rowItem);

      if (this._expandedOptions && !isShown)
        this.table!.addExtension(rowItem, this._extendedDetailsTemplateGenerator(rowItem.id));
      else if (!this._expandedOptions && isShown) this.table!.remExtension(rowItem);
    });

    const indicator = document.querySelector('#quote-item-expand-all-indicator')! as WebmoduleIcon;

    if (this._expandedOptions) indicator.name = 'fas-angles-up';
    else indicator.name = 'fas-angles-down';
  }

  getExtendedDetails(quoteItemContainer: QuoteItemContainer, _class?: 'even' | 'odd') {
    if (isFrame(quoteItemContainer.item)) return this.getFrameExtendedDetails(quoteItemContainer, _class);
    else if (isSpecialItem(quoteItemContainer.item)) return this.getSpecialItemExtendedDetails(quoteItemContainer);

    return this.getOtherLineItemDetails(quoteItemContainer);
  }

  nfrcDisplayColumns(): NFRCColumn[] {
    return this.nfrcOptions?.nfrcOptions().columns ?? [];
  }

  quoteCalculationsEnabled() {
    return this.quoteManager.hasQuoteCalculationData;
  }

  nfrcEnabled() {
    return (
      (this.nfrcOptions?.nfrcOptions().enabled ?? false) &&
      this.nfrcOptions?.nfrcOptions().calculationName !== 'QUOTE_CALCULATIONS'
    );
  }

  nfrcColumnValue(quoteItemContainer: QuoteItemContainer, publishedCode: string): number {
    return this.nfrcOptions?.nfrcColumnValue(quoteItemContainer, publishedCode) ?? NaN;
  }

  getQuoteItemTags(quoteItemContainer: QuoteItemContainer) {
    const validationIndicator = () => {
      const state = this.getItemValidationType(quoteItemContainer);
      switch (state) {
        case FrameValidationType.note:
          return html` <webmodule-icon library="fa" name="fas-file-lines" class="text-blue"></webmodule-icon>`;
        case FrameValidationType.information:
          return html` <webmodule-icon library="fa" name="fas-circle-info" class="text-cyan"></webmodule-icon>`;
        case FrameValidationType.warning:
          return html` <webmodule-icon
            library="fa"
            name="fas-triangle-exclamation"
            class="text-yellow"
          ></webmodule-icon>`;
        case FrameValidationType.critical:
          return html` <webmodule-icon library="fa" name="fas-circle-exclamation" class="text-red"></webmodule-icon>`;
        case FrameValidationType.outOfDate:
          return html` <webmodule-icon library="fa" name="fas-wrench" class="text-red"></webmodule-icon>`;
      }
      if (!quoteItemContainer.price.isTaxableItem) {
        return html` <webmodule-icon library="default" name="no-tax" class="text-base"></webmodule-icon>`;
      }
      return html``;
    };
    const notesIndicator = () => {
      if (quoteItemContainer.item.comment)
        return html` <webmodule-icon library="fa" name="fas-message" class="text-green"></webmodule-icon>`;
      else return html``;
    };

    const supplierPriceAdjIndicator = () => {
      if (quoteItemContainer.price.supplierPriceAdjustment != 0)
        return html` <webmodule-icon library="fa" name="fas-tags" class="text-base"></webmodule-icon>`;
      else return html``;
    };

    const dealerPriceAdjIndicator = () => {
      if (quoteItemContainer.price.priceAdjustment != 0)
        return html` <webmodule-icon library="fa" name="fas-tags" class="text-blue"></webmodule-icon>`;
      else return html``;
    };

    return html`${supplierPriceAdjIndicator()}${dealerPriceAdjIndicator()}${notesIndicator()}${validationIndicator()}`;
  }

  generateQuoteItemOptionsView(quoteItemContainer: QuoteItemContainer, childVisible: boolean) {
    if (childVisible)
      return html`<span>${this.getOptionsOneLiner(quoteItemContainer)}</span
        ><span class="float-end options-dropdown"><i class="fa-solid fa-caret-up"></i></span>`;
    else
      return html`<span>${this.getOptionsOneLiner(quoteItemContainer)}</span
        ><span class="float-end options-dropdown"><i class="fa-solid fa-caret-down"></i></span>`;
  }

  getItemValidationType(quoteItemContainer: QuoteItemContainer): FrameValidationType {
    //we are currently only supporting items that are for v6
    if (!isV6(quoteItemContainer)) return FrameValidationType.nothing;

    const data = getV6FrameForContainer(quoteItemContainer);
    if (!data) return FrameValidationType.nothing;

    if (this.quoteManager.currentStateRequiresValidation) {
      if (this.quoteManager.isFrameOutOfDate(quoteItemContainer, [], true)) return FrameValidationType.outOfDate;
    }
    // no validations on the frame, no point trying to find the highest ranked
    if (data.validations?.length == 0) {
      return FrameValidationType.nothing;
    }

    return rankToValidationType(highestValidationRank(data.validations));
  }

  getOptionsOneLiner(quoteItemContainer: QuoteItemContainer) {
    //We should only progress if item is a configuration from supplier.
    if (!isV6(quoteItemContainer)) return '';
    //We are only here if we are a v6 Frame
    const data = getV6QuoteItemForContainer(quoteItemContainer);
    return getV6QuoteItemFrameDimensions(data);
  }

  ellipsisMenu(quoteItem: QuoteItem) {
    const clickOpen = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.openItem(quoteItem);
    };

    const clickCopy = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.copyItem(quoteItem);
    };

    const clickDelete = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.deleteItem(quoteItem);
    };

    const clickProperties = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.propertiesItem(quoteItem);
    };
    const clickBomViewer = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.showBomView(quoteItem);
    };

    const canShowBom =
      (isCynclyStaff() || isSupplierAgent() || isDebugMode()) &&
      v6SupportsVersion(v6VersionMap.hasBomView) &&
      isFrame(quoteItem);

    return html` <webmodule-dropdown class="item-menu text-start" placement="bottom-end" hoist>
      <webmodule-icon-button slot="trigger" library="fa" name="fas-bars"></webmodule-icon-button>
      <webmodule-menu>
        ${when(
          canShowBom,
          () =>
            html` <webmodule-menu-item @click=${clickBomViewer}>
                <webmodule-icon slot="prefix" library="fa" name="fas-list-check"></webmodule-icon>
                Bom View
              </webmodule-menu-item>
              <webmodule-divider></webmodule-divider>`
        )}
        ${when(
          this.quoteManager.isReadonly() || isShipping(quoteItem) || isSSI(quoteItem),
          () => html``,
          () =>
            html` <webmodule-menu-item @click=${clickDelete}>
              <webmodule-icon slot="prefix" library="fa" name="fas-trash"></webmodule-icon>
              Delete
            </webmodule-menu-item>`
        )}
        ${when(
          this.quoteManager.isReadonly() ||
            isFreehand(quoteItem) ||
            isShipping(quoteItem) ||
            isSpecialItem(quoteItem) ||
            isSSI(quoteItem),
          () => html``,
          () =>
            html` <webmodule-menu-item @click=${clickCopy}>
              <webmodule-icon slot="prefix" library="fa" name="fas-copy"></webmodule-icon>
              Copy
            </webmodule-menu-item>`
        )}
        ${when(
          isSSI(quoteItem),
          () => html``,
          () =>
            html` <webmodule-menu-item @click=${clickOpen}>
              <webmodule-icon slot="prefix" library="fa" name="fas-pen-to-square"></webmodule-icon>
              Edit
            </webmodule-menu-item>`
        )}

        <webmodule-menu-item @click=${clickProperties}>
          <webmodule-icon slot="prefix" library="fa" name="fas-sliders"></webmodule-icon>
          Pricing
        </webmodule-menu-item>
      </webmodule-menu>
    </webmodule-dropdown>`;
  }

  async showBomView(quoteItem: QuoteItem) {
    const container = this.quoteManager.quoteItemContainer(quoteItem.id);
    const v6Data = getV6QuoteItemForContainer(container);
    const url = this.quoteApi.api.fullUrl(`api/file/${quoteItem.virtualThumbnailPath}`);
    const quoteTitle = getQuoteTitleStr(this.quoteManager.quote);
    const itemTitle = quoteItem.description;
    const itemPos = this.quoteManager.itemPosition(container.item.id);
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    const title = `${quoteTitle} - #${itemPos} ${itemTitle}`;
    this.quoteManager.quote;
    const viewer = await constructAsync(
      new V6BomViewer(title, {
        id: quoteItem.id,
        quantity: quoteItem.quantity,
        quoteItem: v6Data,
        thumbnailURL: url
      })
    );

    await viewer.showModal();
  }

  _extendedDetailsTemplateGenerator = id => () => this.getExtendedDetails(this.quoteManager.quoteItemContainer(id));

  protected async firstUpdated(_changedProperties: PropertyValues) {
    await this.refreshData();
    this._columnsData = this.getColumns();
    this.updateCols();
  }

  protected render() {
    const keyEvent = (item: QuoteItem) => {
      return item.id;
    };

    const rowIdEvent = (item: QuoteItem) => {
      return item.id;
    };

    const itemRowClassEvent = (item: QuoteItem) => {
      if (!isSpecialItem(item)) return '';
      const bqs = this.quoteManager.branchQuoteSupport;
      const link = bqs.conversationLinks.find(x => x.quoteItemId === item.id);
      if (!link) return '';
      const support = bqs.items.find(x => x.conversationId === link.conversationId);
      if (!support) return '';

      return `quotesupport-status-${BranchQuoteSupportStatus[support.status].toLowerCase()}`;
    };

    const enableDragEvent = (item: QuoteItem): boolean => {
      return !(item && (isSSI(item) || isShipping(item)));
    };

    return html` <webmodule-lit-table
      id="quote-items-table"
      class="quote-items-table"
      .rowClass=${'tr'}
      .colClass=${'column'}
      .keyevent=${keyEvent}
      .itemRowClassEvent=${itemRowClassEvent}
      .rowidevent=${rowIdEvent}
      .checkEnabledDragEvent=${enableDragEvent}
      tablestyle="nestedtable"
      .columns=${this._columns}
      pageLength="100"
      .data=${this._data}
      .clickrows=${false}
      @webmodule-table-item-move=${this._handleMove}
      .enableDrag=${!this.quoteManager.isReadonly() ? 'true' : undefined}
    >
    </webmodule-lit-table>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected getNotesTemplate(quoteItemContainer: QuoteItemContainer) {
    if (!isEmptyOrSpace(quoteItemContainer.item.comment)) {
      return html` <ul class="list-group">
        <li class="list-group-item list-group-item-primary">
          <span class="me-3 fw-bold">Notes: </span>
          ${quoteItemContainer.item.comment}
        </li>
      </ul>`;
    }
    return html``;
  }

  private async _handleMove(e: CustomEvent<WebModuleTableItemMove>) {
    const newData = this.moveArrayItem([...this._data], e.detail).map(x => x.commonId);

    if (await this.quoteManager.updateItemSortOrder(newData)) {
      await this.requestUpdate();
    }
  }

  private moveArrayItem<T>(array: T[], moveData: WebModuleTableItemMove): T[] {
    const { sourceIndex, targetIndex } = moveData;

    // Ensure the indices are within the bounds of the array
    if (
      sourceIndex < 0 ||
      sourceIndex >= array.length ||
      targetIndex < 0 ||
      targetIndex >= array.length ||
      sourceIndex === targetIndex
    ) {
      throw new Error('Invalid indices provided');
    }

    // Remove the item from the original position
    const item = array.splice(sourceIndex, 1)[0];

    // Insert the item at the new position
    array.splice(targetIndex, 0, item);

    return array;
  }

  private async openItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.edit
    });
  }

  private async copyItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.copy
    });
  }

  private async deleteItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.delete
    });
  }

  private async propertiesItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.properties
    });
  }

  private getColumns(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];

    const addColumnDef = (columnId: string, column: WebModuleLitTableColumnDef) => {
      cols.push({ ...column, id: columnId, sortable: false });
    };

    addColumnDef('number', {
      title: 'Item #',
      classes: 'colpxmax-64 quote-item-no',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, index: number) => {
        const rowItem = item as QuoteItem;

        if (rowItem.isRestrictedToPowerUser) return html`${index + 1}. <span class="power-user"></span>`;
        return html`${index + 1}`;
      }
    });
    addColumnDef('thumbnail', {
      title: 'Image',
      classes: 'colpxmax-64 quote-item-image',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const row = item as QuoteItem;

        const src =
          row.virtualThumbnailPath === ''
            ? quoteItemThumbSrc(row)
            : this.quoteApi.api.fullUrl(`api/file/${row.virtualThumbnailPath}`);

        const imagePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();

          if (isFrame(row) || !isEmptyOrSpace(row.virtualThumbnailPath))
            this.imagePreview?.showImagePreview(
              this.quoteApi.api.fullUrl(`api/file/${row.virtualThumbnailPath}`),
              'quote-item-thumb'
            );
        };

        const hidePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
          this.imagePreview?.hideImagePreview();
        };

        return html` <div
          @mouseenter=${imagePreview}
          @mouseleave=${hidePreview}
          @touchstart=${imagePreview}
          @touchend=${hidePreview}
          @touchcancel=${hidePreview}
        >
          <img
            class="quote-item-thumbnail"
            alt="Thumbnail for quote item"
            src="${src}"
            style="width:48px; height:48px"
          />
        </div>`;
      }
    });
    addColumnDef('location', {
      title: tlang`%%frame-title%% and Description`,
      classes: 'colpx-240 quote-item-title',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const clickEvent = async (e: Event) => {
          e.stopPropagation();
          e.preventDefault();

          await this.openItem(rowItem);
        };

        const title = isEmptyOrSpace(rowItem.title) ? tlang`%%frame-title%%` : rowItem.title;

        return html`<a class="quote-item-link" href="#" @click="${clickEvent}"
            >${firstValidString(title, tlang`Untitled`)}</a
          >
          <span class="quote-item-description">${rowItem.description}</span>`;
      }
    });

    const expandButton = html`
      <span>Options</span>
      <div>
        <webmodule-icon
          id="quote-item-expand-all-indicator"
          library="fa"
          name="fas-angles-down"
          @click=${() => (this._expandedOptions = !this._expandedOptions)}
        ></webmodule-icon>
      </div>
    `;

    addColumnDef('options', {
      title: html`${expandButton}`,
      classes: 'colpx-160 quote-item-description',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        try {
          const container = this.quoteManager.quoteItemContainer(rowItem.id);

          return this.generateQuoteItemOptionsView(container, _table.isExpanded(rowItem) != undefined);
        } catch {
          return html``;
        }
      },
      click: (e: Event, table: WebModuleLitTable, item: unknown) => {
        e.stopPropagation();
        e.preventDefault();

        const rowItem = item as QuoteItem;

        const isShown = table.isExpanded(rowItem);

        if (isShown) table.remExtension(rowItem);
        else {
          table.addExtension(rowItem, this._extendedDetailsTemplateGenerator(rowItem.id));
        }

        return true;
      }
    });

    const tooltipIcons = html` <webmodule-tooltip placement="right" class="table-header-tooltip" hoist>
      <div slot="content">
        <div>
          <webmodule-icon library="fa" name="fas-message" class="text-green"></webmodule-icon>
          <span>${tlang`Note`}</span>
        </div>
        <small>Frame Configuration</small>
        <webmodule-divider></webmodule-divider>
        <div>
          <webmodule-icon library="fa" name="fas-circle-info" class="text-cyan"></webmodule-icon>
          <span>${tlang`Information`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-file-lines" class="text-blue"></webmodule-icon>
          <span>${tlang`Supplier Note`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-triangle-exclamation" class="text-yellow"></webmodule-icon>
          <span>${tlang`Warning`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-circle-exclamation" class="text-red"></webmodule-icon>
          <span>${tlang`Critical Error`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-wrench" class="text-red"></webmodule-icon>
          <span>${tlang`Require Validation`}</span>
        </div>

        <small>Pricing</small>
        <webmodule-divider></webmodule-divider>
        <div>
          <webmodule-icon library="fa" name="fas-tags" class="text-blue"></webmodule-icon>
          <span>${tlang`Dealer Price Adjustment`}</span>
        </div>
        <div>
          <webmodule-icon library="fa" name="fas-tags" class="text-base"></webmodule-icon>
          <span>${tlang`Supplier Price Adjustment`}</span>
        </div>
        <div>
          <webmodule-icon library="default" name="no-tax" class="text-base"></webmodule-icon>
          <span>${tlang`No Tax`}</span>
        </div>
      </div>

      <span>${tlang`Tags`} <webmodule-icon library="fa" name="far-circle-question"></webmodule-icon></span>
    </webmodule-tooltip>`;

    addColumnDef('tags', {
      title: tooltipIcons,
      classes: 'colpxmax-64 quote-item-tags no-pseudo',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        try {
          const container = this.quoteManager.quoteItemContainer(rowItem.id);

          return this.getQuoteItemTags(container);
        } catch {
          return html``;
        }
      }
    });
    addColumnDef('qty', {
      title: 'Qty',
      classes: 'colpxmax-48 quote-item-quantity',
      fieldName: 'quantity'
    });
    addColumnDef('lastModified', {
      title: 'Last Modified',
      classes: 'colpxmax-100 quote-item-modified no-pseudo',
      fieldName: 'lastModifiedDate',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const dt = new Date(rowItem.lastModifiedDate!);
        return html`${dt.toLocaleDateString()} <br />
          ${dt.toLocaleTimeString()}`;
      }
    });

    if (this.quoteCalculationsEnabled()) {
      const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
      const qcdata = data.quoteCalculations as QCQuoteValues;
      const colDefs: QCValue[] = [];
      if (qcdata) {
        qcdata.quoteItemValues.forEach(x => {
          x.values.forEach(col => {
            if (col.displayColumn && colDefs.find(x1 => x1.name == col.name) === undefined) colDefs.push(col);
          });
        });
        for (let iQCV = 0; iQCV < colDefs.length; iQCV++) {
          const qcCol = colDefs[iQCV];
          addColumnDef(qcCol.name, {
            title: lang(firstValidString(qcCol.caption, qcCol.name)),
            classes: 'colpxmax-98 dt-right nfrc-col',
            fieldName: 'xx',
            displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
              const rowItem = item as QuoteItem;

              let colValue = '';
              if (isFrame(rowItem)) {
                const qcVal = qcdata.quoteItemValues
                  .find(x => x.quoteItemId === rowItem.id)
                  ?.values.find(val => val.name == qcCol.name);

                if (qcVal) colValue = firstValidString(qcVal.displayValue, tryAsNumberString(qcVal.value, 2));
              }
              return html`${colValue}`;
            }
          });
        }
      }
    }
    if (this.nfrcEnabled()) {
      const nfrcCols = this.nfrcDisplayColumns();

      for (let iNFRC = 0; iNFRC < nfrcCols.length; iNFRC++) {
        const nfrcCol = nfrcCols[iNFRC];
        addColumnDef(nfrcCol.code, {
          title: lang(nfrcCol.title),
          classes: 'colpxmax-98 dt-right nfrc-col',
          fieldName: 'xx',
          displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
            const rowItem = item as QuoteItem;

            let colValue = '';
            if (isFrame(rowItem)) {
              const colResult = this.nfrcColumnValue(this.quoteManager.quoteItemContainer(rowItem.id), nfrcCol.code);
              if (!isNaN(colResult)) colValue = colResult.toFixed(2);
            }
            return html`${colValue}`;
          }
        });
      }
    }

    addColumnDef('extendedListPrice', {
      title: 'Ext. List Price',
      classes: 'colpxmax-110 dt-right quote-item-price-sup-ext-list supplier-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isFreehand(rowItem)) return '';

        const value = this.quoteManager.getQuoteItemExtendedListPrice(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemExtendedListPriceTotal())
    });
    addColumnDef('multiplier', {
      title: 'Multi.',
      classes: 'colpxmax-60 quote-item-price-sup-multiplier supplier-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isFreehand(rowItem)) return '';

        const multiplier = this.quoteManager.getQuoteItemMultiplier(rowItem.id);
        return number4dpToHtml(multiplier);
      }
    });
    addColumnDef('priceAdjustmentSupplier', {
      title: 'Supplier Price Adj.',
      classes: 'colpxmax-130 dt-right quote-item-price-sup-adj supplier-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, rowItem: QuoteItem, _index: number) => {
        if (isFreehand(rowItem)) return '';

        const value = this.quoteManager.getQuoteItemSupplierPriceAdjustment(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemSupplierPriceAdjustmentTotal())
    });
    addColumnDef('dealerCost', {
      title: 'Cost Price',
      classes: 'colpxmax-110 dt-right quote-item-price-del-cost dealer-price-col',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const value = this.quoteManager.getQuoteItemDealerCost(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemDealerCostTotal())
    });

    addColumnDef('margin', {
      title: `Pricing Metric (%)`,
      classes: 'colpxmax-130 quote-item-price-del-margin dealer-price-col',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const value = this.quoteManager.getQuoteItemMarginOrMarkupPercent(rowItem.id);
        return html`${number2dpToHtml(value)}%`;
      }
    });

    addColumnDef('priceAdjustment', {
      title: 'Dealer Price Adj.',
      classes: 'colpxmax-120 dt-right quote-item-price-del-adj dealer-price-col',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const value = this.quoteManager.getQuoteItemDealerPriceAdjustment(rowItem.id);
        return moneyToTemplateResult(value);
      },
      columnAggregate: () => moneyToTemplateResult(this.quoteManager.getQuoteItemDealerPriceAdjustmentTotal())
    });
    addColumnDef('price', {
      title: ' Selling Price',
      classes: 'colpxmax-110 dt-right quote-item-price dealer-price-col',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const price = this.quoteManager.quoteItemPrice(rowItem.id, _table.isExpanded(rowItem) != undefined);
        return moneyToTemplateResult(price);
      },
      columnAggregate: () => {
        return moneyToTemplateResult(this.quoteManager.quoteItemPriceTotal);
      }
    });

    return cols;
  }

  private getBuyInValidation(quoteItemContainer: QuoteItemContainer) {
    return this.quoteManager.getPriceValidation(quoteItemContainer.item.id);
  }

  private getSpecialItemExtendedDetails(quoteItemContainer: QuoteItemContainer) {
    const warningTemplate = (): TemplateResult => {
      if (!isEmptyOrSpace(quoteItemContainer.item.description)) return html``;

      return html`
        <ul class="list-group">
          <li class="list-group-item list-group-item-warning">
            <span class="me-3 fw-bold">${tlang`%%supplier%% Reference`}</span>
            ${tlang`The %%supplier%% must provide a reference`}
          </li>
        </ul>
      `;
    };

    return html` <div class="extended-options-line">
      ${warningTemplate()} ${this.getNotesTemplate(quoteItemContainer)}
    </div>`;
  }

  private getOtherLineItemDetails(quoteItemContainer: QuoteItemContainer) {
    if (
      !(
        (isShipping(quoteItemContainer.item) ||
          isDefaultShipping(quoteItemContainer.item) ||
          isFreehand(quoteItemContainer.item)) &&
        !isEmptyOrSpace(quoteItemContainer.item.comment)
      )
    )
      return html``;

    return html` <div class="extended-options-line">${this.getNotesTemplate(quoteItemContainer)}</div>`;
  }

  private getFrameExtendedDetails(quoteItemContainer: QuoteItemContainer, classMap?: 'even' | 'odd'): TemplateResult {
    //only here if we are a frame and also a v6 frame
    if (!isV6(quoteItemContainer)) return html``;
    if (!isValidV6ConfigVersion()) {
      const message = tlang`%%supplier%% is OFFLINE`;
      return html`${message}`;
    }

    const data = getV6FrameForContainer(quoteItemContainer);

    const validationsTemplate = (
      validationType: FrameValidationType,
      cssClass: string
    ): TemplateResult[] | TemplateResult => {
      const stuff = data?.validations?.filter(x => x.type === validationType) ?? [];
      if (stuff.length === 0) return html``;
      const rows = stuff.map(issue => {
        return html` <li class="list-group-item ${cssClass}">
          <span class="me-3 fw-bold">${issue.title}</span>${lang(issue.text ?? '')}
        </li>`;
      });
      return html`
        <ul class="list-group">
          ${rows}
        </ul>
      `;
    };

    const validationTemplates = html` <div class="extended-options-line">
      ${data ? getFrameDetailsTemplate(data, true) : undefined}
      ${validationsTemplate(FrameValidationType.critical, 'list-group-item-danger')}
      ${validationsTemplate(FrameValidationType.warning, 'list-group-item-warning')}
      ${validationsTemplate(FrameValidationType.information, 'list-group-item-info')}
      ${validationsTemplate(FrameValidationType.note, 'list-group-item-note')}
      ${this.getNotesTemplate(quoteItemContainer)}
    </div>`;

    const getBuyInRow = (buyInItem: FrameBuyInItem, heightFirst: null | boolean) => {
      const nfrcRows = () => {
        const s: TemplateResult[] = [];
        if (this.nfrcEnabled()) {
          const nfrcCols = this.nfrcDisplayColumns();
          for (let iNFRC = 0; iNFRC < nfrcCols.length; iNFRC++) {
            s.push(html` <div class="colpx-90 dt-right nfrc-col"></div>`);
          }
        }
        return s;
      };

      const buyIn = this.quoteManager.lookupBuyInData(
        quoteItemContainer,
        buyInItem.code,
        buyInItem.extraDetails?.['SuppCode'] ?? '',
        `Length=${buyInItem.extraDetails?.['Length'] ?? ''};Width=${buyInItem.extraDetails?.['Width'] ?? ''};Height=${buyInItem.extraDetails?.['Height'] ?? ''}`
      );

      const codeDisplay = () => {
        return html`(${buyInItem.extraDetails?.['SuppCode'] ?? ''} - ${buyInItem.code})`;
      };

      const priceValidations = () => {
        const validations = this.getBuyInValidation(quoteItemContainer);

        if (validations?.details && validations.details.length > 0)
          return validations.details.map(x => html`<br /><span class="text-danger">${x.message}</span>`);

        return html``;
      };

      const titleDisplay = (description: string | undefined) => {
        return html`${description ?? ''} ${codeDisplay()} ${priceValidations()}`;
      };

      return html` <div class="extended-options-line">
        <div class="tr ${classMap}">
          <div class="column colpxmax-64 quote-item-no"></div>
          <div class="column colpxmax-64 quote-item-image">
            ${supplierItemContentTypeDisplay(buyInItem.resourceType, false)}
          </div>
          <div class="column colpx-240 quote-item-title">${titleDisplay(buyIn?.description)}</div>
          <div class="column colpx-160 quote-item-description">${getBuyInDetailsTemplate(buyInItem, heightFirst)}</div>
          <div class="column colpxmax-48 quote-item-quantity">${buyInItem.quantity}</div>
          <div class="column colpxmax-160 quote-item-modified"></div>
          ${nfrcRows()}
          <div class="column colpxmax-120 dt-right quote-item-price">
            ${buyIn?.calculatedGross != undefined ? moneyToTemplateResult(buyIn?.calculatedGross) : ''}
          </div>
          <div class="column colpxmax-48 item-menu"></div>
        </div>
      </div>`;
    };

    const quoteItemData = getV6QuoteItemForContainer(quoteItemContainer);
    const heightFirst = getV6QuoteItemDisplayHeightFirst(quoteItemData);

    return html`${data?.buyIn?.map((item, _index) => getBuyInRow(item, heightFirst))} ${validationTemplates}`;
  }
}

@customElement('wm-franchiseequoteitemsview')
export class FranchiseeQuoteItemsView extends QuoteItemsView {
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  quoteApi: QuoteApi = getApiFactory().quote();
  supplierApi = getApiFactory().supplier();
  franchiseeApi = getApiFactory().franchisee();
  @query('#franchisee-quote-item-table')
  table?: FranchiseeQuoteItemTable;
  private _supplierPricingConfig?: ResultGetSupplierPricingRule;
  private nfrcOptions?: NFRCOptions;
  private pricingModel: PricingModel = new PricingModel(pricingModelType.margin, 0, 99);
  private settingsManager?: SettingsManager;
  private _supplierDisplayName: string = tlang`%%supplier%%`;

  constructor(options: QuoteItemsViewOptions) {
    super(options);

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `quoteprice-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.quoteManager.container.quotePrice),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    const addDynamicMoney = (
      fieldName: string,
      getter: EventValueGetter,
      setter: EventValueSetter,
      readonly?: () => boolean
    ) => {
      this.dataTracker.addBinding(
        new DynamicValueBinder(FieldType.money, false, getter, setter, readonly),
        fieldName,
        fieldName,
        FieldType.money,
        false
      );
    };

    addField('termsAndConditions', FieldType.string, false, undefined, () => this.quoteManager.quote);

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.int,
        true,
        () => this.quoteManager.container.quotePrice?.percentMarginOrMarkup ?? 0,
        () => {},
        () => true
      ),
      'dealerQuoteMargin',
      'dealerQuoteMargin',
      FieldType.int,
      true
    );

    addDynamicMoney(
      'calculatedTaxAmount',
      () => this.quoteManager.quotePrice.calculatedTaxAmount,
      () => {
        console.log('calculatedTaxAmount value set');
      },
      () => true
    );
    addDynamicMoney(
      'calculatedGrossTotal',
      () => this.quoteManager.quotePrice.calculatedGrossTotal,
      () => {
        console.log('calculatedGrossTotal value set');
      },
      () => true
    );
    addDynamicMoney(
      'subTotal',
      () => this.quoteManager.quotePrice.calculatedLineItemTotal + this.quoteManager.quotePrice.priceAdjustment,
      () => {
        console.log('subTotal set event');
      },
      () => true
    );
    if (userDataStore.getFranchiseeConfig().franchiseeConfiguration.allowNonTaxableItems) {
      addDynamicMoney(
        'taxableSubTotal',
        () => this.quoteManager.taxableQuoteItemPriceTotal + this.quoteManager.quotePrice.priceAdjustment,
        () => {
          console.log('taxableSubTotal set event');
        },
        () => true
      );
    }
    addDynamicMoney(
      'itemTotal',
      () => this.quoteManager.quotePrice.calculatedLineItemTotal,
      () => {
        console.log('itemTotal set event');
      },
      () => true
    );
    addDynamicMoney(
      'priceAdjustment',
      () => this.quoteManager.quotePrice.priceAdjustment,
      () => {
        console.log('priceAdjustment value set');
      },
      () => true
    );
  }

  private _nfrcDataCache?: NFRCDataCache;

  get nfrcDataCache(): NFRCDataCache {
    if (!this._nfrcDataCache)
      this._nfrcDataCache = new NFRCDataCache(() => {
        if (!this.nfrcOptions) throw new DevelopmentError('nfrcOptions are not initialized');
        return this.nfrcOptions;
      });
    return this._nfrcDataCache;
  }

  protected get qcm(): FranchiseeQuoteContainerManager {
    return this.quoteManager as FranchiseeQuoteContainerManager;
  }

  public async refreshData(): Promise<void> {
    await this.getNFRCOptions();
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.
    if (this.nfrcOptions?.enabled) this.nfrcDataCache.clear();

    await this.table?.refreshData();
    this.requestUpdate();
  }

  quoteCalculationsEnabled() {
    return (this.quoteManager as FranchiseeQuoteContainerManager).hasQuoteCalculationData;
  }

  async afterConstruction(): Promise<void> {
    await super.afterConstruction();

    const systemDefaults = await this.franchiseeApi.getSystemConfiguration();
    const supplierOverrides = await this.supplierApi.getSupplierQuoteConfig({
      supplierId: this.quoteManager.quote.supplierId
    });

    const v6Columns: { code: string; title: string }[] = [];

    const nfrcCols = await this.getNFRCOptions();
    if (nfrcCols && nfrcCols.enabled && nfrcCols.columns.length > 0) {
      nfrcCols.columns.map(x => v6Columns.push({ code: x.code, title: x.title }));
    }

    if (this.quoteCalculationsEnabled()) {
      const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
      const qcdata = data.quoteCalculations as QCQuoteValues;
      const colDefs: QCValue[] = [];
      if (qcdata) {
        qcdata.quoteItemValues.forEach(x => {
          x.values.forEach(col => {
            if (col.displayColumn && colDefs.find(x1 => x1.name == col.name) === undefined) colDefs.push(col);
          });
        });
        for (let iQCV = 0; iQCV < colDefs.length; iQCV++) {
          const qcCol = colDefs[iQCV];
          v6Columns.push({ code: qcCol.name, title: lang(firstValidString(qcCol.caption, qcCol.name)) });
        }
      }
    }

    //Check system V6 columns
    if (
      v6Columns &&
      v6Columns.length > 0 &&
      systemDefaults?.defaultDealerConfiguration?.quoteItemColumnConfigurations
    ) {
      const v6ColConfig: TableColumnConfiguration[] = [];

      v6Columns.map(x =>
        v6ColConfig.push({
          code: x.code,
          title: x.title,
          systemDefault: false,
          defaultDisplay: true,
          canSwitch: true
        })
      );

      const insertIndex = systemDefaults.defaultDealerConfiguration.quoteItemColumnConfigurations.length - 7;

      systemDefaults.defaultDealerConfiguration.quoteItemColumnConfigurations.splice(insertIndex, 0, ...v6ColConfig);
    }

    this.settingsManager = new SettingsManager(
      systemDefaults?.defaultDealerConfiguration?.quoteItemColumnConfigurations ?? [],
      supplierOverrides?.quoteItemSummaryConfigurations ?? []
    );
    this._supplierDisplayName = await getQuoteSupplierDisplayName(this.quoteManager.quote.supplierId);
    if (!this._supplierPricingConfig) {
      const result = await this.supplierApi.getSupplierPricingRule({
        supplierId: this.quoteManager.quote.supplierId
      });
      const isMargin = this.quoteManager.quotePrice.calculation === QuotePriceCalculation.Margin;
      this.pricingModel.supplierName = this._supplierDisplayName;
      this.pricingModel.update({ calculation: this.quoteManager.quotePrice.calculation, min: null, max: null });
      if (result) {
        const pricingRule = result.pricingRule;

        if (result.pricingRuleType.hasMinMax)
          this.pricingModel.update({
            min: isMargin ? pricingRule.minValue : pricingRule.minMarkup,
            max: isMargin ? pricingRule.maxValue : pricingRule.maxMarkup
          });

        this._supplierPricingConfig = result;
      }
    }
  }

  public async prepareForSave() {
    await this.quoteManager.needsQuoteItems();
    this.dataTracker.applyChangeToValue();
  }

  getTaxLabel(): string | undefined {
    const rate = userDataStore.taxRates.find(taxRate => taxRate.rate == this.quoteManager.quotePrice.taxPercentage);

    if (rate) {
      return tlang`${rate.name} ${rate.rate}%`;
    }

    return tlang`%%tax%% (${this.quoteManager.quotePrice.taxPercentage.toFixed(2)}%)`;
  }

  getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (this.quoteManager.quoteState == QuoteState.Active || this.quoteManager.quoteState == QuoteState.Draft)
      return errors;

    if (this.quoteManager.quoteState == QuoteState.Cancelled) {
      return [];
    }

    if (
      this.quoteManager.container.items &&
      this.quoteManager.container.items.filter(x => !isShipping(x)).length == 0
    ) {
      errors.push(
        tlang`There are no !!quote-item!! in this %%quote%%. A blank %%quote%% will not be issued to the %%client%%.`
      );
    }

    return errors;
  }

  protected eventKeyupPriceAdjustment = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangedPriceAdjustment(e);
    }
  };

  protected eventChangedPriceAdjustment = (_e: Event) => {
    const p = this.quoteManager.quotePrice;
    const value = (this.dataTracker.getEditorValue('priceAdjustment') as number) ?? 0;
    if (p.priceAdjustment === value) return;
    p.priceAdjustment = money(value);
    p.calculatedLineItemTotal = this.quoteManager.quoteItemPriceTotal;
    p.calculatedNetTotal = this.quoteManager.quoteItemPriceTotal + p.priceAdjustment;
    const taxableTotal = money(this.quoteManager.taxableQuoteItemPriceTotal + p.priceAdjustment);
    p.calculatedTaxAmount = p.taxPercentage == 0 ? 0 : taxableTotal * (p.taxPercentage / 100);
    p.calculatedGrossTotal = p.calculatedNetTotal + p.calculatedTaxAmount;
    this.requestUpdate();
  };
  protected eventKeyupGrossTotal = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangedGrossTotal(e);
    }
  };

  protected eventChangedGrossTotal = (_e: Event) => {
    const p = this.quoteManager.quotePrice;
    const finalTotal = (this.dataTracker.getEditorValue('calculatedGrossTotal') as number) ?? 0;
    if (p.calculatedGrossTotal === finalTotal) return;
    const valueLessTax = money(p.taxPercentage === 0 ? finalTotal : finalTotal / (1 + p.taxPercentage / 100));
    p.priceAdjustment = valueLessTax - this.quoteManager.quoteItemPriceTotal;

    this.recalculateQuotePrice();
    console.log(`Expected Total = ${finalTotal.toFixed(4)} and calculated total ${p.calculatedGrossTotal.toFixed(4)}`);
    this.requestUpdate();
  };

  protected eventKeyupQuoteMargin = (e: CustomEvent<KeyboardEvent>) => {
    if (e.detail.code === 'Enter') {
      this.eventChangedQuoteMargin(e);
    }
  };
  protected eventChangedQuoteMargin = async (_e: Event) => {
    const p = this.quoteManager.quotePrice;
    const value = (this.dataTracker.getEditorValue('dealerQuoteMargin') as number) ?? 0;
    const errors = this.pricingModel.getValueValidations(value);
    if (errors.hasErrors) {
      fireQuickWarningToast(errors.asMarkdown());
      this.dataTracker.setEditorValue('dealerQuoteMargin', this.quoteManager.quotePrice.percentMarginOrMarkup);
      return;
    }

    if (p.percentMarginOrMarkup === value) return;

    p.percentMarginOrMarkup = value;

    await this.quoteManager.saveQuote(false);

    //we dont want to trigger the render from inside the events
    //calling this code. so we need to post it out.
    //rendering here can cause update issues with the internals of some edit controls

    await this.table?.refreshData();
    this.requestUpdate();
  };

  protected async getNFRCOptions(): Promise<NFRCOptions> {
    const defaultOptions: NFRCOptions = {
      enabled: false,
      calculationName: '',
      columns: [],
      title: ''
    };
    if (!this.nfrcOptions)
      this.nfrcOptions = isValidV6ConfigVersion()
        ? await v6Util().getNFRCOptions(this.qcm.quote.supplierId)
        : defaultOptions;
    return this.nfrcOptions;
  }

  protected template(): EventTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());

    const addTaxRow = (taxRate: TaxRate): TemplateResult => {
      const taxChange = (_e: Event) => {
        if (this.quoteManager.isReadonly()) return;
        this.quoteManager.quotePrice.taxPercentage = taxRate.rate;

        this.recalculateQuotePrice();
        this.requestUpdate(); //no wait
      };

      return html` <webmodule-menu-item @click=${taxChange}
        >${tlang`${taxRate.name} ${taxRate.rate}%`}
      </webmodule-menu-item>`;
    };

    const taxRates = userDataStore.taxRates.map(rate => addTaxRow(rate));
    const taxTemplate = this.quoteManager.isReadonly()
      ? html`${forms.moneyReadonly('calculatedTaxAmount', this.getTaxLabel())}`
      : html`
          <webmodule-input-money
            class="webmodule-control-left-label summary-tax-input"
            id=${this.dataBinding.field('calculatedTaxAmount')}
            ?readonly=${true}
            ?filled=${true}
            label="${this.getTaxLabel()}"
            value=${this.dataTracker.getObjectDisplayValue('calculatedTaxAmount')}
            size="small"
          >
            <webmodule-dropdown slot="prefix" hoist>
              <webmodule-button
                size="small"
                variant="default"
                id="${this.dataTracker.binder.internalId + '-plus'}"
                slot="trigger"
                caret
                >${tlang`Tax Rate`}
              </webmodule-button>
              <webmodule-menu>${taxRates}</webmodule-menu>
            </webmodule-dropdown>
          </webmodule-input-money>
        `;
    const nfrcTemplate = awaitableTemplate(async (): Promise<TemplateResult[]> => {
      await this.quoteManager.needsQuoteItems();
      const localNFRCOptions = await this.getNFRCOptions();
      const resultTemplate = (item: NFRCValue) => html`
        <div class="nfrc-col">
          <div class="nfrc-col-name">${item.name}</div>
          <div class="nfrc-col-value">${item.value.toFixed(2)}</div>
        </div>
      `;
      const qcResultTemplate = (item: QCValue) => html`
        <div class="quotecalculation-col">
          <div class="quotecalculation-col-name">${item.name}</div>
          <div class="quotecalculation-col-value">
            ${firstValidString(item.displayValue, tryAsNumberString(item.value, 2))}
          </div>
        </div>
      `;

      const sections: TemplateResult[] = [];
      if (localNFRCOptions.enabled) {
        const data = this.nfrcDataCache.nfrcGetResults(this.quoteManager);

        sections.push(html`
          <div class="nfrc-section">
            <div class="nfrc-label">${localNFRCOptions.title}</div>
            <div class="nfrc-result">${data.values.map(x => resultTemplate(x))}</div>
          </div>
        `);
      }
      if (this.qcm.hasQuoteCalculationData) {
        const data = this.qcm.quoteProviderData() as V6FranchiseeQuoteProviderData;
        const qcv = data.quoteCalculations as QCQuoteValues;
        if (qcv) {
          qcv.quoteValues.forEach(element => {
            sections.push(
              html` <div class="quotecalculation-section">
                <div class="quotecalculation-label">${lang(element.title)}</div>
                <div class="quotecalculation-result">${element.values.map(x => qcResultTemplate(x))}</div>
              </div>`
            );
          });
        }
      }
      return sections;
    });

    const dealerName = userDataStore.franchisee.name;
    const termsAndConditions = isEmptyOrSpace(dealerName)
      ? tlang`Dealer Terms and Conditions`
      : tlang`${dealerName} Terms and Conditions`;

    const actionQuoteItem = async (e: CustomEvent) => {
      const quoteItem = e.detail.item as QuoteItem;
      const action = e.detail.quoteAction;

      const qicm = this.quoteManager.quoteItemContainer(quoteItem.id);
      await this.eventRunQuoteItemActions?.(qicm, action);
    };

    const getMenuItems = () => {
      return this.settingsManager?.getActiveConfiguration
        .filter(x => x.canSwitch)
        .map(
          mi =>
            html` <webmodule-menu-item
              .value=${mi.code}
              type="checkbox"
              checked=${mi.defaultDisplay ? true : nothing}
              disabled=${!mi.canSwitch ? true : nothing}
              >${mi.title}
            </webmodule-menu-item>`
        );
    };

    const taxableSubTotalTemplate = userDataStore.getFranchiseeConfig().franchiseeConfiguration.allowNonTaxableItems
      ? html`${forms.moneyReadonly('taxableSubTotal', 'Sub Total (Taxable)')}`
      : html``;

    return html`
      <div class="page-configuration-container">
        <h2 class="page-configuration-title">${tlang`!!frame!!`}</h2>
        <webmodule-dropdown hoist placement="bottom-end" stay-open-on-select>
          <webmodule-icon-button
            class="${classMap({
              'text-danger': !this.settingsManager?.getIsClientFacing
            })}"
            slot="trigger"
            name="fas-gear"
            library="fa"
          ></webmodule-icon-button>

          <webmodule-menu @webmodule-select=${(e: WebmoduleSelectEvent) => this._onDisplayChange(e)}>
            <webmodule-menu-label>Page Configuration</webmodule-menu-label>
            <webmodule-menu-item value="internalView"
              >${this.settingsManager!.getIsClientFacing ? tlang`Show Internal View` : tlang`Show Client Facing View`}
            </webmodule-menu-item>
            <webmodule-divider></webmodule-divider>
            <webmodule-menu-label>Table Configuration</webmodule-menu-label>
            ${getMenuItems()}
          </webmodule-menu>
        </webmodule-dropdown>
      </div>

      <franchisee-quote-item-table
        id="franchisee-quote-item-table"
        .nfrcOptions=${this.nfrcDataCache}
        .columnConfiguration=${this.settingsManager?.getActiveConfiguration ?? []}
        .quoteManager=${this.quoteManager as FranchiseeQuoteContainerManager}
        @wm-quote-item-action=${actionQuoteItem}
      >
      </franchisee-quote-item-table>

      <form class="py-3 px-0 quote-price-summary form-two-col ">
        <div class="row">
          <div class="quote-items-terms">
            <div class="row mb-2 align-items-center form-col-item">
              <webmodule-textarea
                class="label-on-top quote-tnc-block"
                id=${this.dataBinding.field('termsAndConditions')}
                value=${this.dataTracker.getObjectDisplayValue('termsAndConditions') || ''}
                label=${termsAndConditions}
                maxlength="3000"
              ></webmodule-textarea>
            </div>
            ${nfrcTemplate}
          </div>
          <div class="quote-items-summary">
            <div class="quote-items-summary-wrapper">
              ${forms.intRequired('dealerQuoteMargin', this.pricingModel.label, {
                min: this.pricingModel.editorMin,
                max: this.pricingModel.editorMax,
                hidden: this.settingsManager?.getIsClientFacing,
                events: {
                  blur: this.eventChangedQuoteMargin,
                  keyup: this.eventKeyupQuoteMargin
                }
              })}
              ${forms.moneyReadonly('itemTotal', tlang`Items Total`)}
              ${forms.money('priceAdjustment', tlang`Price Adjustment`, {
                events: {
                  blur: this.eventChangedPriceAdjustment,
                  keyup: this.eventKeyupPriceAdjustment
                }
              })}
              ${forms.moneyReadonly('subTotal', tlang`Sub Total`)} ${taxableSubTotalTemplate} ${taxTemplate}
              ${forms.money('calculatedGrossTotal', tlang`Total`, {
                events: {
                  blur: this.eventChangedGrossTotal,
                  keyup: this.eventKeyupGrossTotal
                }
              })}
            </div>
          </div>
        </div>
      </form>
    `;
  }

  private async _onDisplayChange(e: WebmoduleSelectEvent) {
    const item = e.detail.item;

    await saveWithIndicator(async () => {
      if (item.value === 'internalView') {
        this.settingsManager?.toggleClientFacingView();
      } else {
        this.settingsManager?.toggleColumnDisplay(item.value);
      }

      return true;
    });

    this.requestUpdate();
  }

  private recalculateQuotePrice() {
    const p = this.quoteManager.quotePrice;
    p.calculatedLineItemTotal = money(this.quoteManager.quoteItemPriceTotal);
    p.calculatedNetTotal = money(p.calculatedLineItemTotal + p.priceAdjustment);
    const taxableTotal = money(this.quoteManager.taxableQuoteItemPriceTotal + p.priceAdjustment);
    p.calculatedTaxAmount = money(p.taxPercentage == 0 ? 0 : taxableTotal * (p.taxPercentage / 100));
    p.calculatedGrossTotal = money(p.calculatedNetTotal + p.calculatedTaxAmount);
  }
}
