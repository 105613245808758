import { AddressEditor } from '../../../webmodule-common/other/ui/address-editor';
import { Branch } from '../../api/dealer-api-interface-franchisee';
import { clone, compare } from '../../../webmodule-common/other/clone';
import { customElement, property } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { html, LitElement } from 'lit';
import { tlang } from '../../../webmodule-common/other/language/lang';

@customElement('wm-franchisee-branch-editor')
export class WebModuleFranchiseeBranchEditor extends LitElement {
  @property() readonly = false;
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));
  private _backupBranch?: Branch;

  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.branch),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    /*
export interface Branch {
    dateCreated: string;
    id: string;
    recordVersion: string;
    franchiseeId: string;
    abbreviation: string;
    name: string;
    physicalAddress: Address;
    billingAddress: Address | null;
    physicalAddressAsDefaultShipping: boolean;
}

        */
    addField('abbreviation', FieldType.string, false);
    addField('name', FieldType.string, false);
    addField('taxRegistrationNumber', FieldType.string, false);
    this.dataTracker.eventChange = this.changeEvent;
  }

  @property()
  private _branch?: Branch | undefined;

  public get branch(): Branch | undefined {
    return this._branch;
  }

  public set branch(value: Branch | undefined) {
    this._branch = clone(value);
    this._backupBranch = clone(value);
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-be-${name}`, options));
  }

  branchChangedEvent() {
    this.dispatchCustom('changed', {
      branch: this.branch
    });
  }

  render() {
    const forms = new FormInputAssistant(this.dataTracker, this.readonly);
    forms.immediateBindingUpdate = true;
    if (!this.branch) return html``;
    return html`
      <div class="form-two-col p-3 branch-form">
        <h2>${tlang`%%branch%% Settings`}</h2>
        <div class="row">
          <div>${forms.text('name', tlang`Name`, 100)}</div>
          <div>${forms.text('abbreviation', tlang`Abbreviated Name`, 20)}</div>
        </div>
        <div class="row">
          <div>${forms.text('taxRegistrationNumber', tlang`Tax Registration #`, 100)}</div>
        </div>
        <wm-addresseditor
          @wm-ae-changed=${(e: Event) => this.addressModified(e.currentTarget as AddressEditor)}
          id="physicalAddressEditor"
          .address=${this.branch.physicalAddress}
          .shippingNotes=${this.branch.shippingNotes}
          .title=${tlang`Physical Address`}
          .readonly=${this.readonly}
          .isDefaultShippingVisible=${true}
          .isDefaultShipping=${this.branch.physicalAddressAsDefaultShipping}
        ></wm-addresseditor>
      </div>
    `;
  }

  addressModified(editor: AddressEditor) {
    if (!this.branch) return;
    if (editor.address) this.branch.physicalAddress = clone(editor.address);
    this.branch.shippingNotes = editor.shippingNotes ?? '';
    this.branch.physicalAddressAsDefaultShipping = editor.isDefaultShippingVisible && editor.isDefaultShipping;
    this.branchChangedEvent();
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private changeEvent = () => {
    if (!compare(this._branch, this._backupBranch)) {
      this._backupBranch = clone(this._branch);
      this.requestUpdate();
      this.branchChangedEvent();
    }
  };
}
