/* eslint-disable @typescript-eslint/no-non-null-assertion */
// eslint-disable-next-line import/named
import { constructAsync } from '../../../webmodule-common/other/async-constructor.js';
import { customElement } from 'lit/decorators.js';
import { CynclySupportView } from './cyncly-support-view.js';
import { DataEntryPageControlView } from '../../../webmodule-common/other/ui/data-entry-screen-base.js';
import { DataProvider } from '../../../webmodule-common/other/data-provider.js';
import { Franchisee, InputUpdateFranchiseeConfiguration } from '../../api/dealer-api-interface-franchisee.js';
import { FranchiseeBrandingView } from './franchisee-branding-view.js';
import { FranchiseeConfigurationView } from './franchisee-configuration-view.js';
import { franchiseeDataAdaptor } from '../data/franchisee-data-adaptor.js';
import { FranchiseeInformationView } from './franchisee-information-view.js';
import { FranchiseeStaffView } from './franchisee-staff-view.js';
import { FranchiseeStatusView } from './franchisee-status-view.js';
import { FranchiseeStockView } from './franchisee-stock-view.js';
import { FranchiseeSupplierSettingsView} from './franchisee-supplier-settings-view.js';
import { isCynclyStaff } from '../../../webmodule-common/other/currentuser-claims.js';
import {
  MenuIconOption,
  PageButtonLocation,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../../webmodule-common/other/ui/page-control.js';
import { Snippet } from '../../../webmodule-common/other/ui/events.js';
import { tlang } from '../../../webmodule-common/other/language/lang.js';

@customElement('wm-franchiseeview')
export class FranchiseeView extends DataEntryPageControlView {
  private informationView: FranchiseeInformationView | null = null;
  private staffView: FranchiseeStaffView | null = null;
  private cynclyStaffView: CynclySupportView | null = null;
  private configurationView: FranchiseeConfigurationView | null = null;
  private stockView: FranchiseeStockView | null = null;
  private statusView: FranchiseeStatusView;
  private brandingView: FranchiseeBrandingView | null = null;
  private supplierSettingsView: FranchiseeSupplierSettingsView | null = null;

  franchiseeDataProvider: DataProvider<Franchisee> = new DataProvider<Franchisee>(
    () => franchiseeDataAdaptor().getFranchisee(),
    async franchisee => await franchiseeDataAdaptor().setFranchisee(franchisee)
  );
  franchiseeConfigDataProvider: DataProvider<InputUpdateFranchiseeConfiguration> =
    new DataProvider<InputUpdateFranchiseeConfiguration>(
      () => {
        return franchiseeDataAdaptor().getFranchiseeConfig();
      },
      async (item: InputUpdateFranchiseeConfiguration) =>
        await franchiseeDataAdaptor().setFranchiseeConfig({
          franchiseeConfiguration: item.franchiseeConfiguration,
          taxRates: item.taxRates
        })
    );

  constructor() {
    super();
    this.statusView = new FranchiseeStatusView();
  }
  public async setActiveTabByHash() {
    await this.pageControl.applyWindowHash();
  }

  protected name(): string {
    return 'franchisee-settings';
  }

  public async prepareForSave(): Promise<void> {
    //DOES NOTHING, HANDLED BY CHILDREN
  }
  public async afterConstruction(): Promise<void> {
    this.configurationView = new FranchiseeConfigurationView(this.franchiseeConfigDataProvider);
    this.brandingView = await constructAsync(new FranchiseeBrandingView(this.franchiseeDataProvider));
    this.stockView = await constructAsync(new FranchiseeStockView());
    this.informationView = await constructAsync(new FranchiseeInformationView());
    this.staffView = await constructAsync(new FranchiseeStaffView());
    this.supplierSettingsView = await constructAsync(new FranchiseeSupplierSettingsView());

    if (isCynclyStaff()) this.cynclyStaffView = await constructAsync(new CynclySupportView());
    //this creates the page control;
    await super.afterConstruction();
    this.pageControl.pageButtonLocation = PageButtonLocation.right;
  }

  createStatusesPage(): PageManager {
    return {
      caption: () => tlang`Status`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: undefined,
      content: () => {
        return this.statusView;
      },
      data: this.statusView,
      pageFragment: 'status'
    };
  }

  /**
   * inherited;
   * @returns
   */
  public internalDataChanged(): boolean {
    return false;
  }

  /**
   * inherited
   * @returns
   */
  public getDataDictionaryName(): string {
    return '%%franchisee%%';
  }
  /**
   * inherited
   * @returns
   */
  public isDataReadonly(): boolean {
    return false;
  }

  public getTitle(): Snippet {
    return '%%franchisee%% Settings';
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      pages.push(this.informationView!.createPageManager());
      pages.push(this.configurationView!.createPageManager());
      pages.push(this.supplierSettingsView!.createPageManager());
      if (this.stockView && this.stockView.ShowTab) pages.push(this.stockView.createPageManager());

      pages.push(this.createStatusesPage());

      pages.push(this.brandingView!.createPageManager());

      pages.push(this.staffView!.createPageManager());
      if (this.cynclyStaffView && isCynclyStaff()) pages.push(this.cynclyStaffView.createPageManager());

      return pages;
    };
    const menu: MenuIconOption[] = [];
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: menu,
      //TODO - DO NOT FORGET THIS CODE
      // hide this temporarily for demo, will be implemented in next PR
      // plusPage: {
      //     caption: () => html`${new IconPlus()} %%branch%%`,
      //     event: async () => {
      //         alert("Add create branch modal here");
      //         return true;
      //     }
      // },
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }
}
