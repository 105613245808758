import { Branch } from '../../api/dealer-api-interface-franchisee';
import {
  canClose,
  ErrorAbandon,
  isAutoSaving,
  SavePromptOptions,
  saveWithIndicator
} from '../../../webmodule-common/other/save-workflow';
import { checkValidations, throwValidations } from '../../../webmodule-common/other/ui/data-entry-screen-helpers';
import { customElement, property, query } from 'lit/decorators.js';
import { DataProvider, ProviderBackup } from '../../../webmodule-common/other/data-provider';
import { DevelopmentError, showDevelopmentError } from '../../../webmodule-common/other/development-error';
import { emptyAddress, singleLineAddress } from '../../../webmodule-common/other/ui/maps/map-helpers';
import { emptyGuid, guidEquals } from '../../../webmodule-common/other/api/guid';
import { franchiseeDataAdaptor } from '../data/franchisee-data-adaptor';
import { getCurrentUser } from '../../../webmodule-common/other/api/current-user';
import { html, LitElement, TemplateResult } from 'lit';
import { isEmptyOrSpace, validId } from '../../../webmodule-common/other/ui/string-helper-functions';
import { IUserClaims, userClaims } from '../../../webmodule-common/other/currentuser-claims';
import { LitBaseModal } from '../../../webmodule-common/other/ui/modal/modal-factory-lit';
import { localDateToServer } from '../../../webmodule-common/other/datetime-converter';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userSecurity } from '../../../webmodule-common/other/api/user-security';
import { WebModuleFranchiseeBranchEditor } from './franchisee-branch-editor';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

@customElement('wm-franchisee-branches-editor')
export class WebModuleFranchiseeBranchesEditor extends LitElement {
  @query('#branches-table')
  table?: WebModuleLitTable;
  @property()
  franchiseeId = '';
  @property() filterValue = '';
  @property() filterActive = '';
  @property() readonly = false;
  claims: IUserClaims = userClaims(getCurrentUser()?.id ?? emptyGuid, userSecurity());

  private _data?: Branch[] | undefined;

  protected get data(): Branch[] | undefined {
    return this._data;
  }

  @property({ type: Array })
  protected set data(value: Branch[] | undefined) {
    this._data = value;
    this.table?.fetchEvent();
  }

  render() {
    const keyEvent = (item: Branch) => {
      return item.id;
    };
    const columns: WebModuleLitTableColumnDef[] = [];
    columns.push({
      title: tlang`%%branch%% Name`,
      classes: 'colpx-280',
      fieldName: 'name',
      // Item is of type Branch
      displayValue: (_table: WebModuleLitTable, item) => {
        const branch = item as Branch;

        const clickItem = (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          this.editBranch(branch);
        };
        return html`<a href="#" @click=${clickItem}>${branch.name}</a>`;
      }
    });
    columns.push({
      title: tlang`Abbreviated Name`,
      classes: 'colpx-160',
      fieldName: 'abbreviatedName',
      displayValue: (_table: WebModuleLitTable, item) => {
        const branch = item as Branch;
        return branch.abbreviation;
      }
    });
    columns.push({
      title: tlang`Physical Address`,
      classes: 'colpx-300',
      fieldName: 'physicalAddress',
      displayValue: (_table: WebModuleLitTable, item) => {
        const branch = item as Branch;
        return singleLineAddress(branch.physicalAddress);
      }
    });
    columns.push({
      title: tlang`Billing Address`,
      classes: 'colpx-300',
      fieldName: 'billingAddress',
      displayValue: (_table: WebModuleLitTable, item) => {
        const branch = item as Branch;
        return singleLineAddress(branch.billingAddress);
      }
    });

    return html` <div class=" branches-editor">
      <webmodule-lit-table
        id="branches-table"
        .rowClass=${'tr'}
        .colClass=${'column'}
        .keyevent=${keyEvent}
        tablestyle="nestedtable"
        .columns=${columns}
        pageLength="14"
        @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        .clickrows=${false}
        @fetchtemplate=${(e: CustomEvent) => {
          this._loadExtension(e);
        }}
      >
      </webmodule-lit-table>
    </div>`;
  }

  async editBranch(item: Branch) {
    const d = new BranchEditDialog(item, this.readonly);
    await d.showModal();
    if (d.currentBranch.recordVersion !== item.recordVersion) {
      this.branchChangedEvent(d.currentBranch);
    }
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-be-${name}`, options));
  }

  connectedCallback(): void {
    super.connectedCallback();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  branchChangedEvent(branch: Branch) {
    this.dispatchCustom('changed', {
      branch: branch
    });
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private matchFilter(vbl: Branch): boolean {
    function matches(a: string, b: string) {
      return a === '' || a.toLowerCase().includes(b);
    }

    if (this.claims.isSingleBranchManager && !guidEquals(vbl.id, this.claims.branchId)) return false;
    if (this.claims.isBasicUser && !guidEquals(vbl.id, this.claims.branchId)) return false;
    return matches(this.filterValue, vbl.name);
  }

  /** This is called by the table when it wants new page data */
  private async internalDataLoad(e: CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const table = e.detail.table as WebModuleLitTable;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 1000;
    if (!this.data) {
      table.data = [];
    }
    if (this.data) {
      table.data = this.data.filter(x => this.matchFilter(x)).slice(index * length, index * length + length);
      table.rowCount = this.data.length;
    } else table.data = [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _loadExtension(_e: CustomEvent) {
    //const item: Tenant = e.detail.item;
    //e.detail.table?.addExtension(item, this.getExtensionForTenant());
  }
}

class BranchEditDialog extends LitBaseModal {
  backupBranch: ProviderBackup<Branch>;
  abandoned: any;
  branch: Branch;
  readonly: boolean;

  constructor(branch: Branch, readonly: boolean) {
    super();
    this.branch = branch;
    this.readonly = readonly;
    this.backupBranch = new ProviderBackup<Branch>(
      new DataProvider<Branch>(
        () => this.branch,
        async b => {
          const newBranch = await franchiseeDataAdaptor().setBranch(b);
          if (newBranch) this.branch = newBranch;
          return newBranch;
        }
      )
    );
  }

  get currentBranch(): Branch {
    if (!this.backupBranch) throw new DevelopmentError('current branch does not exist');
    return this.backupBranch?.item;
  }

  get isCreating(): boolean {
    return !validId(this.currentBranch.id);
  }

  get isFooterVisible(): boolean {
    return true;
  }

  protected get modalSize() {
    return 'modal-fullscreen';
  }

  title(): string | TemplateResult {
    return this.isCreating ? tlang`Add %%branch%%` : tlang`Edit %%branch%%`;
  }

  public getValidationErrors(): string[] {
    if (this.readonly) return [];
    const errors: string[] = [];

    if (isEmptyOrSpace(this.currentBranch.taxRegistrationNumber)) {
      errors.push(tlang`Please provide a %%tax%% Registration Number`);
    }

    if (isEmptyOrSpace(this.currentBranch.name)) {
      errors.push(tlang`Please provide a %%branch%% name`);
    }

    if (isEmptyOrSpace(this.currentBranch.abbreviation)) {
      errors.push(tlang`Please provide a %%branch%% abbreviated name`);
    }

    const physicalLine1 = this.currentBranch.physicalAddress.line1;
    const physicalPostCode = this.currentBranch.physicalAddress.postcode;

    if (isEmptyOrSpace(physicalLine1)) {
      errors.push('Please provide a Street Address');
    }

    if (isEmptyOrSpace(physicalPostCode)) {
      errors.push('Please provide a Zip Code');
    }

    if (this.currentBranch.billingAddress) {
      const billingLine1 = this.currentBranch.billingAddress?.line1;
      const billingPostCode = this.currentBranch.billingAddress?.postcode;

      if (isEmptyOrSpace(billingLine1)) errors.push(tlang`Please provide a Billing Street Address`);

      if (isEmptyOrSpace(billingPostCode)) errors.push(tlang`Please provide a Billing Address Postcode`);
    }
    return errors;
  }

  updateCurrentBranch(branch: Branch) {
    this.backupBranch.pushChangedItem(branch);
  }

  bodyTemplate(): TemplateResult {
    const branchChangedEvent = (e: CustomEvent<{ branch: Branch }>) => {
      e.stopImmediatePropagation();
      const be = e.currentTarget as WebModuleFranchiseeBranchEditor;
      if (be.branch) this.updateCurrentBranch(be.branch);
    };
    return html` <wm-franchisee-branch-editor
      .branch=${this.currentBranch}
      @wm-be-changed=${branchChangedEvent}
      .readonly=${this.readonly}
    ></wm-franchisee-branch-editor>`;
  }

  async performAutoSave(options: SavePromptOptions): Promise<boolean> {
    //if we dont need to save, then just exit.
    //but if we are not in autosave mode, we are going to force a save.
    let needsSave = false;
    try {
      needsSave = await options.needsSaveEvent();
    } catch (e) {
      await showDevelopmentError(e as Error);
      return false;
    }

    //this is to let us abandon new items that have had no editing
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (!needsSave && this.isCreating) throw new ErrorAbandon('Cancel', tlang`Cancel New Edit`);

    throwValidations(this.getValidationErrors());

    if (isAutoSaving() && !needsSave) return true;

    //this is our basic save indicator
    return await saveWithIndicator(async () => await this.internalSaveData());
  }

  async internalSaveData() {
    const b = await this.backupBranch.commitChanges();
    return b !== null;
  }

  getAutoSavePromptOptions(): SavePromptOptions {
    return {
      isReadonly: this.readonly,
      autoSaveEvent: async options => {
        return await this.performAutoSave(options);
      },
      dictionaryName: '%%branch%%',
      needsSaveEvent: async () => this.backupBranch.changed,
      abandonSaveEvent: async () => {
        this.abandoned = true;
        this.backupBranch.reset();
        return true;
      },
      displaySaveModal: false,
      informationDispatcher: undefined
    };
  }

  async canClose(): Promise<boolean> {
    return this.abandoned || (await canClose(this.getAutoSavePromptOptions()));
  }

  footerTemplate(): TemplateResult | null | undefined {
    const saveEvent = async () => {
      if (await this.canClose()) {
        await this.hideModal();
      }
    };

    return html`
      <webmodule-button size="small" variant="default" @click=${() => this.hideModal()}
        >${tlang`Close`}
      </webmodule-button>
      <webmodule-button size="small" variant="primary" @click=${() => saveEvent()}
        >${tlang`Save %%branch%%`}
      </webmodule-button>
    `;
  }

  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    return await checkValidations(errors);
  }
}

export async function createBranch(): Promise<Branch | undefined> {
  const newBranch: Branch = {
    abbreviation: '',
    taxRegistrationNumber: '',
    billingAddress: null,
    franchiseeId: franchiseeDataAdaptor().getFranchisee().id,
    id: emptyGuid,
    name: '',
    physicalAddress: emptyAddress(),
    physicalAddressAsDefaultShipping: false,
    dateCreated: localDateToServer(new Date()),
    recordVersion: '',
    shippingNotes: ''
  };
  const d = new BranchEditDialog(newBranch, false);
  await d.showModal();
  if (d.currentBranch.recordVersion !== newBranch.recordVersion) {
    return d.currentBranch;
  }
  return undefined;
}
